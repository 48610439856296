import { useCallback, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { tempTask } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/constants";
import { selectCurrentDate } from "@store/screenDay";

export const useStartData = () => {
  const [data, setData] = useState({ ...tempTask, priority: "URGENT" });
  const [isCancel, setIsCancel] = useState(false);

  const dateNow = useSelector(selectCurrentDate);

  const handleData = useCallback(
    (name: string, value: any) => {
      setData({
        ...data,
        [name]: value,
      });
    },
    [data],
  );

  return useMemo(
    () => ({
      data,
      handleData,
      isCancel,
      setIsCancel,
      dateNow,
    }),
    [data, handleData, isCancel, dateNow],
  );
};
