import React, { FC, useEffect, useMemo, useRef, useState, MouseEvent, useCallback } from "react";

import { isArray, unionBy } from "lodash";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgAddPeople } from "@assets/people_add.svg";
import { Groups } from "@components/screens/profile/components";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { SelectAllButton } from "@components/shared/userList/SelectAllButton";
import { NotificationConfirm } from "@components/ui/notification/NotificationConfirm";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser } from "@interfaces/user.interface";
import { InviteExternalParticipantBottomSheet } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { AddNonExistingParticipantButton } from "@screens/day/components/timetable/main/modal/components/participants/components/AddNonExistingParticipantButton";
import { IExternalUser } from "@services/meet.service";
import { useDeleteExternalParticipantMutation } from "@services/meetApi";
import { useCreateExternalUsersByEmailListMutation } from "@services/userApi";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { ArrowSvg, Loader, ModalUI, SearchInput, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { isExternalUser } from "@utils/userTypeChecks";

import { useHandleParticipantSearch } from "../../../../hooks/useHandleParticipantSearch";
import { EmailItem } from "../../../participants/participantsList/EmailItem";
import { Item } from "../../../participants/participantsList/Item";
import { MeetsPastList } from "../../../participants/participantsList/MeetsPastList";
import RemoveParticipantsButton from "../../../participants/participantsList/RemoveParticipantsButton";
import { TStringUnknownTupleArray } from "../../../utils";
import { ICommonFields } from "../../../viewNotSave/types";
import { convertIStateUserIntoMeetParticipant } from "../../utils";
import { Participants } from "../participants/Participants";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps extends ICommonFields {
  data: IMeet;
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  isUserHasEditPermissions: boolean;
  participantsAttendance: IParticipantAttendance[] | null;
  handleOnGroupAll: () => void;
  deletedExternalUsersIds?: string[];
  setCreateUsersByEmailsStatus?: React.Dispatch<React.SetStateAction<"IN_PROGRESS" | "FINISHED">>;
  setDeletedExternalUsersIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setRefetchOnClose?: React.Dispatch<React.SetStateAction<boolean>>;
  rootHandleData?: (name: string | TStringUnknownTupleArray, value: unknown) => void;
}

export const TabletParticpants: FC<IProps> = ({
  data,
  setParticipantLight,
  participantLight,
  isUserHasEditPermissions,
  handleData,
  rootHandleData,
  participantsAttendance,
  handleOnGroupAll,
  deletedExternalUsersIds,
  setDeletedExternalUsersIds,
  setRefetchOnClose,
  setCreateUsersByEmailsStatus,
}) => {
  const freezedInitialParticipants = useRef({
    participants: data?.participants ?? [],
    externalUsers: data?.externalUsers ?? [],
  });

  const {
    search,
    trimmedSearch,
    listData,
    isLoading,
    isFetching,
    isInviteExternalParticipantBtnVisible,
    isInviteExternalParticipantModalVisible,
    searchedExternalUsersResponse,
    isMultipleEmails,
    isCheckBtnHidden,
    isSelectAllChecked,
    selectedEmails,
    selectedUsers,
    isSelectAllBtnVisible,
    inputMaxLength,
    isCheckingForMultipleEvents,
    handleOnPaste,
    setSearch,
    setCurrent,
    handleResetSearch,
    loadMoreData,
    setGetUsersParams,
    setIsInviteExternalParticipantModalVisible,
    setSearchedExternalUsers,
    setSelectedUsers,
    setSelectedEmails,
    handleEmailsSearch,
    injectCreatedExternalParticipant,
    handleEmailPress,
    manualSetIsSelectAllChecked,
    setAllExternalUsers,
    setSkipSearchByEmail,
    setIsManualSearchByEmailsLock,
  } = useHandleParticipantSearch({
    meet: data,
    showOnlySearched: true,
    ignoreSelectedUsers: true,
    customInitialParticipants: [
      ...freezedInitialParticipants.current.participants,
      ...freezedInitialParticipants.current.externalUsers,
    ] as (IStateUser | IExternalUser)[],
  });

  const listInnerRef = useRef();
  const [isWriting, setIsWriting] = useState(false);
  const [isListPastMeetsVisible, setIsListPastMeetsVisible] = useState(false);
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const [isBlockSearchCall, setIsBlockSearchCall] = useState(false);

  const [pos, setPos] = useState({ left: 0, top: 0 });
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [externalUserIdToDelete, setExternalUserIdToDelete] = useState("");

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const deletableUserIds = useMemo(() => new Set<string>(), []);
  const [currentEditableUser, setCurrentEditableUser] = useState<IExternalUser>();
  const [emailToCreateUser, setEmailToCreateUser] = useState<string | null>(null);
  const { directorId } = userSelector();
  const isNotFoundVisible = useMemo(
    () => !listData.length && !isFetching && !isWriting && !isInviteExternalParticipantBtnVisible,
    [listData, isFetching, isWriting, isInviteExternalParticipantBtnVisible],
  );

  const [deleteExternalParticipant] = useDeleteExternalParticipantMutation();
  const [createExternalUsersByEmailList, { data: usersByEmails, isSuccess: isUsersSuccessfullyCreated }] =
    useCreateExternalUsersByEmailListMutation();

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  const handleEmailPressWrapper = useCallback(
    (email: string) => {
      handleEmailPress(email);

      if (!setCreateUsersByEmailsStatus) return;

      const isLastEmailWillBeUnchecked = selectedEmails.includes(email) && selectedEmails.length === 1;

      setCreateUsersByEmailsStatus?.(isLastEmailWillBeUnchecked ? null : "IN_PROGRESS");
    },
    [handleEmailPress, selectedEmails],
  );

  const handleAddUser = (
    users: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[],
    isExternalUser: boolean | Partial<IUnitedGroupItem>[],
  ) => {
    const isGroupAll = users[0]?.type === "ALL_IN_SPACE";
    const onlyOwnerUser = data.participants?.filter((user) => user.status === "OWNER");

    if (isArray(users)) {
      const externalUsers = [];
      const innerUsers: IMeetParticipants[] = [];

      for (const user of users) {
        const isInner =
          Object.prototype.hasOwnProperty.call(user, "workPosition") ||
          user?.user?.roles ||
          user?.user?.permissions ||
          user?.permissions ||
          user?.roles;
        const userId = user?.user?.id ?? user.id;
        const isIncludeParticipant = data.participants?.some((i) => i.id === userId || i.userId === userId);
        const isIncludeExternal = data.externalUsers?.some((i) => i.id === userId || i.userId === userId);

        deletableUserIds.add(userId);

        if (!isIncludeParticipant && !isIncludeExternal) {
          if (isInner) {
            innerUsers.push(convertIStateUserIntoMeetParticipant(user as IMeetParticipants));
          } else {
            externalUsers.push(user);
          }
        }
      }

      handleData(
        isGroupAll
          ? [
              ["participants", onlyOwnerUser],
              ["externalUsers", externalUsers],
              ["presetGroups", [{ type: "ALL_IN_SPACE" }]],
              ["repeat", []],
            ]
          : [
              ["participants", [...(data.participants ?? []), ...innerUsers]],
              ["externalUsers", [...(data.externalUsers ?? []), ...externalUsers]],
            ],
        null,
      );
      isGroupAll && handleOnGroupAll();
    } else {
      if (!users) return;

      const propToUpdate = isExternalUser ? "externalUsers" : "participants";
      const dataArr = (isExternalUser ? data?.externalUsers : data?.participants) ?? [];
      const updation = dataArr.filter((item) => item.id !== users.id);
      const isAddedPreviously = dataArr.some((item) => {
        if (isExternalUser) {
          return item.id === users.id || item?.userId === users.id || item?.email === users.email;
        }

        return item.id === users.id || item?.userId === users.id;
      });

      if (isAddedPreviously) {
        const updatedData = (() => {
          if (isExternalUser) {
            return data.externalUsers?.filter((user) => user.userId !== users.id && user.id !== users.id && user.email !== users.email);
          }

          return data.participants?.filter((user) => user.userId !== users.id);
        })();

        handleData(propToUpdate, updatedData);

        deletableUserIds.delete(users.id);
      } else {
        const innerUserTemplate = {
          userId: users.id,
          status: null,
          user: users,
          meetingStatus: null,
          statusComment: null,
          statusCommentIsViewed: null,
          permissions: [],
        } as unknown as IMeetParticipants;

        const userToAdd = isExternalUser ? ({ ...users } as IExternalUser) : innerUserTemplate;
        updation.push(userToAdd);
        deletableUserIds.add(users.id);

        handleData(propToUpdate, updation);
      }
    }
  };

  const handleSearchInputFocus = () => {
    setIsSearchVisible(true);
    setIsBlockSearchCall(false);
  };

  const handleEmailSearchWrapper = () => {
    handleEmailsSearch();
    setIsSearchVisible(true);
  };

  const updateExternalParticipant = (user: IExternalUser) => {
    let isAdded = false;

    const updatedParaticipants = data.externalUsers.map((item) => {
      if (item.email === user.email) {
        isAdded = true;

        return {
          ...item,
          firstName: user.firstName,
          lastName: user.lastName,
        };
      }

      return item;
    });

    const origininalParticipants = new Map([...(data?.externalUsers ?? [])].map((item) => [item.email, item]));

    if (origininalParticipants.has(user.email)) {
      origininalParticipants.set(user.email, { ...origininalParticipants.get(user.email), ...user });
    } else {
      origininalParticipants.set(user.email, user);
    }

    const mergedArray = Array.from(origininalParticipants.values());

    isAdded && rootHandleData?.("externalUsers", mergedArray);
    handleData("externalUsers", updatedParaticipants);
    setRefetchOnClose?.(true);
  };

  const handleCloseSearchResultOverlay = useCallback(async () => {
    if (selectedEmails && selectedEmails.length) {
      await createExternalUsersByEmailList({ data: { emails: selectedEmails }, userId: directorId });
    }

    setIsSearchVisible(false);
    setCreateUsersByEmailsStatus?.("FINISHED");
    setSelectedEmails([]);
  }, [createExternalUsersByEmailList, directorId, selectedEmails]);

  const isAdded = (item: string | IStateUser | IExternalUser) => {
    if (typeof item === "string") {
      return selectedEmails.some((i) => i === item);
    }

    return (
      data.participants?.some((i: IMeetParticipants) => i.userId === item.id) ||
      data?.externalUsers?.some((i: IExternalUser) => i.id === item.id || i.email === item.email)
    );
  };

  const handleSelectAllCheckbox = useCallback(
    (value: boolean) => {
      manualSetIsSelectAllChecked(value);

      const listDataUsers: (IExternalUser | IStateUser)[] = [];
      const emails = [];

      for (const item of listData) {
        if (typeof item === "string") {
          emails.push(item);
          handleEmailPressWrapper(item);
        } else {
          listDataUsers.push(item);
        }
      }

      if (value) {
        const innerUsers: IMeetParticipants[] = [];
        const externalUsers = [];

        for (const user of listDataUsers) {
          if (isExternalUser(user)) {
            externalUsers.push(user);
          } else {
            innerUsers.push(convertIStateUserIntoMeetParticipant(user));
          }
        }

        const mergedInnerParticipants = [...(data.participants ?? []), ...innerUsers].filter(
          (item, index, array) =>
            array.findIndex((obj) => obj.id === item.id || (obj as unknown as IMeetParticipants)?.userId === item.id) === index,
        );

        handleData([
          ["externalUsers", unionBy(data.externalUsers ?? [], externalUsers, "id")],
          ["participants", mergedInnerParticipants],
        ]);
      } else {
        const clearedSearchResultFromParticipants = (data?.externalUsers ?? []).filter(
          (extUsers) => !listDataUsers.some((user) => user.id === extUsers.id),
        );

        const filteredExternalUsers = unionBy(
          clearedSearchResultFromParticipants,
          freezedInitialParticipants.current.externalUsers,
          "email",
        );

        const filteredInnerUsers = (data?.participants ?? []).filter(
          (participant) =>
            !listDataUsers.some((user) => {
              if (Object.prototype.hasOwnProperty.call(participant, "user")) {
                return user.id === participant.userId;
              }
              return user.id === participant.id;
            }),
        );

        handleData([
          ["externalUsers", filteredExternalUsers],
          ["participants", filteredInnerUsers],
        ]);
      }
    },
    [data.externalUsers, data.participants, listData, handleEmailPressWrapper, handleData, manualSetIsSelectAllChecked],
  );

  useEffect(() => {
    if (isUsersSuccessfullyCreated && usersByEmails) {
      setSelectedEmails([]);

      handleData("externalUsers", [...(data.externalUsers ?? []), ...usersByEmails]);
    }
  }, [usersByEmails, isUsersSuccessfullyCreated]);

  useEffect(() => {
    const externalUsers = [];

    for (const item of selectedUsers) {
      if ((item as unknown as IMeetParticipants)?.isExternalUser) {
        externalUsers.push(item);
      }
    }

    handleData([
      ["externalUsers", unionBy(data?.externalUsers ?? [], externalUsers, "id")],
      ["participants", data?.participants ?? []],
    ]);
  }, [selectedUsers]);

  useEffect(() => {
    if (isBlockSearchCall) return;

    if (trimmedSearch) {
      setIsWriting(true);
    }

    const query = trimmedSearch ? { query: trimmedSearch } : {};
    const additionalParams = trimmedSearch ? {} : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false };
    const params: IGetUserParams = {
      id: data?.id ?? "",
      params: { ...query, current: 0, next, startTime: data?.startTime ?? "", endTime: data?.endTime, active: true, ...additionalParams },
    };

    if (!trimmedSearch) setGetUsersParams(params);

    eventHelperUtils.debounce(
      async () => {
        setGetUsersParams(params);
        setCurrent(next);
        setIsWriting(false);
      },
      trimmedSearch ? 1000 : 0,
    );
  }, [trimmedSearch, searchedExternalUsersResponse]);

  useEffect(() => {
    document.getElementById("modalActivityParticipant").addEventListener("click", (e) => {
      if (!document.getElementById("listParticipant")?.contains(e?.target as Node)) {
        setIsSearchVisible(false);
      }
    });

    return () => document.removeEventListener("click", () => setIsSearchVisible);
  }, []);

  useEffect(() => {
    if (isMultipleEmails && !isSearchVisible && Boolean(selectedEmails.length)) {
      handleCloseSearchResultOverlay();
    }
  }, [isMultipleEmails, isSearchVisible, selectedEmails]);

  useEffect(() => {
    const handleCloseModal = () => {
      if (isOpenContextMenu) {
        setIsOpenContextMenu(false);
      }
    };

    document.addEventListener("click", handleCloseModal);

    return () => document.removeEventListener("click", handleCloseModal);
  }, [isOpenContextMenu]);

  const handleRightClick = async (e: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => {
    e.preventDefault();
    setExternalUserIdToDelete(item.id);
    setIsOpenContextMenu(true);
    setPos({ top: e.clientY, left: e.clientX });
  };

  const removeExternalParticipant = (externalUserId: string) => {
    deleteExternalParticipant({ externalUserId, meetId: data.id, isMeet: true, disableUpdate: true }).then(() => {
      setSearchedExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setAllExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setSelectedUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setDeletedExternalUsersIds((prev) => [...prev, externalUserId]);
    });
  };

  const renderSearch = (
    <SearchInput
      isDrop
      value={search}
      styleWrapper={wrapperSearchInput}
      setValue={setSearch}
      onDrop={handleResetSearch}
      placeholder="nameOrEmail"
      autoRenderDrop
      showCheckEmailsButton={isMultipleEmails}
      onCheckEmailsPress={handleEmailSearchWrapper}
      isCheckBtnHidden={isCheckBtnHidden}
      onFocus={handleSearchInputFocus}
      styleInput={{ padding: 8 }}
      maxLength={inputMaxLength}
      onPaste={handleOnPaste}
      autoFocus={false}
      isCheckingForMultipleEvents={isCheckingForMultipleEvents}
    />
  );

  const renderListItems = useCallback(
    () => (
      <>
        {listData.map((item) => {
          if (typeof item === "string") {
            return (
              <EmailItem
                item={item}
                onPress={handleEmailPressWrapper}
                setIsCreationFormVisible={setIsInviteExternalParticipantModalVisible}
                isAdded={isAdded(item)}
                setEmailToCreateUser={setEmailToCreateUser}
              />
            );
          }
          return (
            <>
              <Item
                setCurrentEditableUser={setCurrentEditableUser}
                isDeletable={deletableUserIds?.has(item.id)}
                isAdded={isAdded(item)}
                key={item.id}
                item={item}
                onSave={handleAddUser}
                onRightClick={handleRightClick}
                hover={Colors.LIGHT.lightGrey150}
                isUserHasEditPermissions={isUserHasEditPermissions}
              />
              {(item as IExternalUser).isExternalUser && !(item as IExternalUser).deleted && (
                <RemoveParticipantsButton
                  externalUserId={item.id}
                  isVisible={isOpenContextMenu && item.id === externalUserIdToDelete}
                  position={{ left: pos.left, top: pos.top }}
                  onClick={() => {
                    setExternalUserIdToDelete(item.id);
                    setIsConfirmModalOpen(true);
                  }}
                />
              )}
            </>
          );
        })}
      </>
    ),
    [
      listData,
      deletableUserIds,
      isAdded,
      handleAddUser,
      isUserHasEditPermissions,
      isOpenContextMenu,
      externalUserIdToDelete,
      pos.left,
      pos.top,
    ],
  );

  return (
    <ContentWrap id="listParticipant">
      <Participants
        search={renderSearch}
        participantLight={participantLight}
        data={data}
        setParticipantLight={setParticipantLight}
        handleData={handleData}
        isUserHasEditPermissions={isUserHasEditPermissions}
        participantsAttendance={participantsAttendance}
        deletableUserIds={deletableUserIds}
        deletedExternalUsersIds={deletedExternalUsersIds}
      />

      <ModalRenderWithCondition condition={isSearchVisible && !isBlockSearchCall && (isMultipleEmails ? isCheckBtnHidden : true)}>
        <ModalContentWrapper>
          {!search && (
            <SelectFromPastParticipantButton onClick={() => setIsListPastMeetsVisible(true)}>
              <AddParticipantWrapper>
                <SvgAddPeople width={42} height={42} />
                <TextFont size={16} weight="700" type="bold">
                  <FormattedMessage id="selectParticipantsFromMeetings" />
                </TextFont>
              </AddParticipantWrapper>
              <ArrowSvg type="right" />
            </SelectFromPastParticipantButton>
          )}

          {!search && (
            <SelectFromPastParticipantButton onClick={() => setIsGroupVisible(true)}>
              <AddParticipantWrapper>
                <SvgAddPeople fill={Colors.LIGHT.blue} width={42} height={42} />
                <TextFont size={16} weight="700" type="bold">
                  <FormattedMessage id="selectGroup" />
                </TextFont>
              </AddParticipantWrapper>
              <ArrowSvg type="right" />
            </SelectFromPastParticipantButton>
          )}

          <RenderWithCondition condition={isMultipleEmails && listData?.length && isSelectAllBtnVisible}>
            <SelectAllButton handleCheck={handleSelectAllCheckbox} isChecked={isSelectAllChecked} style={{ marginTop: 8 }} />
          </RenderWithCondition>

          <LoaderRenderWithCondition condition={isLoading}>
            <WrapItems ref={listInnerRef} onScroll={handleScroll}>
              <RenderWithCondition condition={isInviteExternalParticipantBtnVisible && !(isFetching || isWriting)}>
                <AddNonExistingParticipantButton
                  email={search}
                  setIsInviteExternalParticipantModalVisible={setIsInviteExternalParticipantModalVisible}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={isNotFoundVisible}>
                <p>Не найдено</p>
              </RenderWithCondition>
              {renderListItems()}
              <RenderWithCondition condition={isFetching || isWriting}>
                <Loader posY="98%" />
              </RenderWithCondition>
            </WrapItems>
          </LoaderRenderWithCondition>
        </ModalContentWrapper>
      </ModalRenderWithCondition>

      <RenderWithCondition
        condition={isInviteExternalParticipantModalVisible || Boolean(currentEditableUser) || Boolean(emailToCreateUser)}
      >
        <InviteExternalParticipantBottomSheet
          participantEmail={emailToCreateUser ?? search}
          isVisible={isInviteExternalParticipantModalVisible || Boolean(currentEditableUser)}
          setIsVisible={setIsInviteExternalParticipantModalVisible}
          closeParticipant={() => {
            setIsInviteExternalParticipantModalVisible(false);
            setCurrentEditableUser(undefined);
          }}
          setSearch={setSearch}
          injectCreatedExternalParticipant={injectCreatedExternalParticipant}
          user={currentEditableUser}
          isMultipleEmails={isMultipleEmails}
          updateExternalParticipant={updateExternalParticipant}
          setSkipSearchByEmail={setSkipSearchByEmail}
          setIsManualSearchByEmailsLock={setIsManualSearchByEmailsLock}
        />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isListPastMeetsVisible}>
        <MeetsPastList onClose={() => setIsListPastMeetsVisible(false)} onPress={handleAddUser} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isGroupVisible}>
        <ModalUI isVisible={isGroupVisible} onClose={() => setIsGroupVisible(false)}>
          <Groups onClose={() => setIsGroupVisible(false)} forParticipants={true} onSave={handleAddUser} isExistMeetID={!!data?.id} />
        </ModalUI>
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isConfirmModalOpen}>
        <NotificationConfirm
          phraseId="externalUserWillDelete"
          phraseOkId="delete"
          phraseCancelId="cancel"
          onOk={() => {
            removeExternalParticipant(externalUserIdToDelete);
            setIsConfirmModalOpen(false);
          }}
          onCancel={() => {
            setIsConfirmModalOpen(false);
          }}
        />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
};

const ModalContentWrapper = styled.div`
  top: 100px;
  width: 100%;
  z-index: 999;
  padding: 5px 0;
  position: absolute;
`;

const ContentWrap = styled.div`
  position: relative;
`;

const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  min-height: 250px;
  max-height: 400px;
  background-color: ${Colors.LIGHT.background.main};
`;

const wrapperSearchInput = {
  alignItems: "center",
  width: "100%",
  marginTop: 20,
  position: "relative",
};

const SelectFromPastParticipantButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.LIGHT.white};
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;

const AddParticipantWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
