import React, { CSSProperties, FC } from "react";

import { FormattedMessage } from "react-intl";
import { Tooltip as ReactTooltip, TooltipRefProps, ITooltip } from "react-tooltip";

import { Colors } from "@theme/colors";

import { TextFont } from "./TextFont";

interface IElement {
  title: string;
  icon?: JSX.Element;
  onPress: () => void;
  disabled?: boolean;
  styleText?: CSSProperties;
  styleButton?: CSSProperties;
}

interface IProps extends Partial<TooltipRefProps & ITooltip> {
  id: string;
  config: Array<IElement>;
  onClose?: () => void;
  style?: CSSProperties;
}

export const FloatMenu: FC<IProps> = ({ config, onClose, style, id, ...props }) => (
  <ReactTooltip style={{ ...styles.toast, ...style }} id={id} place="bottom" noArrow clickable openOnClick {...props}>
    {config.map((i) => (
      <button
        disabled={i.disabled}
        key={i.title}
        style={{ ...styles.button, ...i.styleButton }}
        onClick={(e) => {
          e.stopPropagation();
          i.onPress();
          onClose?.();
        }}
      >
        {i.icon}
        <TextFont style={{ ...styles.button, ...i.styleText }}>
          <FormattedMessage id={i.title} />
        </TextFont>
      </button>
    ))}
  </ReactTooltip>
);

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    padding: 4,
    maxWidth: 262,
  },
  title: {
    marginLeft: 6,
    width: "90%",
    flexWrap: "wrap",
    fontSize: 16,
  },

  toast: {
    display: "flex",
    "flex-direction": "column",
    maxWidth: 290,
    backgroundColor: Colors.LIGHT.white,
    paddingTop: 4,
    paddingBottom: 4,
    boxShadow: " 0px 2px 6px 0px #00000017",
    zIndex: 999,
    width: 232,
  },
};
