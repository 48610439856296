export function parseOneSAdditionalDescription(text: string) {
  const textDescription = text.replace(/\|/g, "").replace(/\nАвтор задачи:.*\n/g, "");
  const textAuthor = text.match(/\nАвтор задачи:.*\n/g)?.join("") ?? "";
  return `${textAuthor}\n${textDescription}`;
}

export function getWorkDayActionSheetStyles(parentWidth: number, isModalView: boolean) {
  return isModalView
    ? null
    : {
        width: parentWidth,
        right: 0,
        bottom: 80,
      };
}
