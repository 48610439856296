import React, { FC } from "react";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { Colors } from "@theme/colors";
import { FloatMenu } from "@ui";

interface IProps {
  handleDelete: () => void;
  handleEdit: () => void;
  id: string;
}

export const CommentContextMenu: FC<IProps> = ({ handleDelete, handleEdit, id }) => (
  <FloatMenu
    id={`comment-${id}`}
    config={[
      {
        title: "edit",
        icon: <SvgEdit fill={Colors.LIGHT.grey} />,
        onPress: handleEdit,
      },
      {
        title: "delete",
        icon: <SvgDelete />,
        onPress: handleDelete,
        styleText: { color: Colors.LIGHT.red },
      },
    ]}
    openOnClick={false}
    place="bottom-end"
  />
);
