import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { useGetContainerDimensions } from "@hooks/useGetContainerDimensions";
import { useSubscribe } from "@hooks/useSubscribe";
import { ITask } from "@interfaces/businessGoals.interface";
import { TSingleSections } from "@interfaces/businessTask.interface";
import { TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { tightModeElements, ListSingleSection } from "@shared";
import { selectGroupedTasks, getGroupedList, selectBusinessTasks, updatePosition } from "@store/businessTask";
import { TaskSubtypes } from "@store/businessTask";
import { sortTasksForOneS } from "@store/businessTask/utils";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { ItemListContentLoader } from "@ui";
import { isTablet, toFormatDate } from "@utils";

import { SwipeListSections } from "./index";

interface IListTasks {
  handlePress?: (event: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  style?: React.CSSProperties;
}

export const ListTasks: FC<IListTasks> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } = useGetContainerDimensions(containerRef);

  const dispatch = useDispatch<any>();

  const [isVisibleSingleSection, setIsVisibleSingleSection] = useState<TSingleSections | undefined>(undefined);
  const tasks = useSelector(selectGroupedTasks);
  const {
    isLoadingList,
    singleSections: {
      transferred: { content: moveTasks },
      delegated: { content: delegatedTasks },
      takenBack: { content: takenBack },
    },
  } = useSelector(selectBusinessTasks);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const dateNow = useSelector(selectCurrentDate);
  const { directorId } = userSelector();

  useSubscribe(
    "updateTasks",
    () => {
      dispatch(getGroupedList({ date: toFormatDate(dateNow), next: 5, notLoader: true }));
    },
    [dateNow, directorId],
  );

  useEffect(() => {
    dispatch(getGroupedList({ date: toFormatDate(dateNow), next: 5 }));
  }, [dateNow, directorId]);

  const onDragEnd = useCallback(
    (dragDropProps, objectKey: string) => {
      if (!dragDropProps.destination || !dragDropProps.source) return;

      const resultArray = Array.from(tasks[objectKey]);
      const [removed] = resultArray.splice(dragDropProps.source.index, 1);
      resultArray.splice(dragDropProps.destination.index, 0, removed);

      if (!isEqual(resultArray, tasks[objectKey])) {
        const resultObject = { ...tasks, [objectKey]: resultArray };
        dispatch(
          updatePosition({
            date: toFormatDate(dateNow),
            result: resultObject,
            data: resultArray.map((item: ITask, index) => ({ id: item.id, position: index })),
            isPersonalTask: false,
          }),
        );
      }
    },
    [dateNow, tasks],
  );

  const handleClick = (id: string, subType?: TaskSubtypes) => {
    props.handlePress({ id }, "TASK", subType === "PERSONAL");
  };

  const isTightModeTablet = useMemo(() => isTightMode && isTablet, [isTightMode]);
  const closeListSingleSectionModal = useCallback(() => setIsVisibleSingleSection(undefined), []);

  return (
    <ContentWrap style={{ ...props.style }} ref={containerRef} {...props}>
      {isTightModeTablet ? (
        <Content>{tightModeElements.listTasks}</Content>
      ) : (
        <LoaderRenderWithCondition
          condition={isLoadingList && !isVisibleSingleSection}
          loaderComponent={<ItemListContentLoader width={containerWidth} height={containerHeight} />}
        >
          <Content>
            <SwipeListSections
              allowPressIcon
              onDragEnd={onDragEnd}
              press={handleClick}
              data={{
                transferred: [...moveTasks].slice(0, isTablet ? 4 : 2).sort(sortTasksForOneS),
                delegated: [...delegatedTasks].slice(0, isTablet ? 4 : 2),
                takenBack: [...takenBack].slice(0, isTablet ? 4 : 2),
                ...tasks,
              }}
              showAll={{
                transferred: () => setIsVisibleSingleSection("transferred"),
                delegated: () => setIsVisibleSingleSection("delegated"),
                takenBack: () => setIsVisibleSingleSection("takenBack"),
              }}
            />
          </Content>
        </LoaderRenderWithCondition>
      )}

      <ModalRenderWithCondition condition={!!isVisibleSingleSection}>
        <ListSingleSection type={isVisibleSingleSection as TSingleSections} close={closeListSingleSectionModal} />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 86%;
`;
const Content = styled.div`
  height: 100%;
  position: relative;
`;
