import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { LineHorizontal } from "@components/shared";
import { NotificationUI } from "@components/ui/notification/NotificationUI";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IDuplicateNotify {
  isOpen: boolean;
  onCancel?: () => void;
}
export const DuplicateNotify: FC<IDuplicateNotify> = ({ isOpen, onCancel }) => (
  <NotificationUI isVisible={isOpen} onCancel={onCancel} onClose={onCancel} style={styleModal}>
    <StContentWrapDiv>
      <StBlockDiv>
        <TextFont size={16} weight="700" lineHeight={20} align="center">
          <FormattedMessage id={"reminderAlreadyAdded"} />
        </TextFont>
      </StBlockDiv>
      <LineHorizontal />
      <StBlockBtn onClick={onCancel}>
        <TextFont size={16} weight="700" lineHeight={20} color={Colors.LIGHT.text.accent} align="center">
          <FormattedMessage id={"okUpperCase"} />
        </TextFont>
      </StBlockBtn>
    </StContentWrapDiv>
  </NotificationUI>
);

const StContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 254px;
  background-color: ${() => Colors.LIGHT.background.main};
  border-radius: 18px;
`;
const StBlockDiv = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
`;
const StBlockBtn = styled.button`
  display: flex;
  padding: 10px 16px 14px 16px;
  justify-content: center;
`;

const styleModal = {
  width: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
