import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgClose } from "@assets/close.svg";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TSingleSections } from "@interfaces/businessTask.interface";
import { Modal, SwipeListSections } from "@screens/day/components/ListTasks";
import {
  forgetAllTasks,
  paggingTransferredList,
  selectBusinessTasks,
  selectSingleSection,
  setSingleSectionShowList,
} from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { ArrowSvg, Prompting, TextFont } from "@ui";
import { ModalUI, HeaderModal } from "@ui";
import { calendar, toFormatDate, toFormatDayMonth } from "@utils";

const next = 20;
const title: Record<TSingleSections, string> = {
  transferred: "taskFromPreviousDays",
  delegated: "delegatedTasks",
  takenBack: "takenBackDelegatedTasks",
};

interface IProps {
  close: () => void;
  type: TSingleSections;
}

export const ListSingleSection: FC<IProps> = memo(function ListSingleSection({ close, type }) {
  const dispatch = useDispatch<any>();

  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState("");
  const tasks = useSelector(selectSingleSection(type));
  const {
    isLoadingList,
    singleSections: {
      [type]: { total, content },
    },
  } = useSelector(selectBusinessTasks);
  const date = useSelector(selectCurrentDate);
  const current = useMemo(() => content.length, [content.length]);

  useEffect(() => {
    if (!content.length) {
      close();
    }
  }, [content.length]);

  useEffect(() => {
    dispatch(setSingleSectionShowList({ value: true, type }));
    if (current === 5 && total > 5) {
      loadMoreData(15);
    }

    return () => {
      dispatch(setSingleSectionShowList({ value: false, type }));
    };
  }, []);

  const handlePress = (id: string) => {
    setId(id);
    setIsVisible(true);
  };

  const handleForgetAll = () => {
    dispatch(forgetAllTasks(type));
    close();
  };

  const loadMoreData = useCallback(
    async (loadNext: number = next) => {
      if (current >= total) return;
      await dispatch(paggingTransferredList({ params: { date: toFormatDate(date), current, next: loadNext }, type }));
    },
    [current, date, dispatch, total, type],
  );

  const nextElements = useMemo(() => (total - current >= next ? next : total - current), [total, current, next]);

  const renderSectionHeader = useCallback((title) => {
    const dateSection = dayjs(title);
    const dayOfWeek = calendar.getWeekDayRu(dateSection.day()).toLowerCase();
    return (
      <TextFont size={16} style={{ ...styles.pl, ...styles.headerTitle }}>{`Задачи с ${dayOfWeek}. ${toFormatDayMonth(
        dateSection,
      )}`}</TextFont>
    );
  }, []);

  const renderFooter = useMemo(
    () => (
      <div style={styles.wrapperFooter}>
        <RenderWithCondition condition={current && current < total}>
          <LoaderRenderWithCondition condition={isLoadingList && !isVisible}>
            <button onClick={() => loadMoreData()} style={{ ...styles.footer, ...styles.pl }}>
              <TextFont size={18} color={Colors.LIGHT.grey500}>
                <FormattedMessage id="showMore" />
                {` ${nextElements} из ${total - current}`}
              </TextFont>
              <ArrowSvg style={{ marginLeft: 5 }} width={20} height={20} type="down" />
            </button>
          </LoaderRenderWithCondition>
        </RenderWithCondition>
      </div>
    ),
    [current, isLoadingList, isVisible, nextElements, total, loadMoreData],
  );

  return (
    <ModalUI isVisible={true} scrollEnable={false} onClose={close}>
      <HeaderModal title={title[type]} leftSide={{ onPressClose: close }} />
      <div style={{ ...styles.container }}>
        <SwipeListSections
          data={tasks}
          press={handlePress}
          typeTasks={type}
          sectionTitle={renderSectionHeader}
          footer={renderFooter}
          forceAllowForget
        />

        <div style={{ display: "flex", bottom: 0, position: "sticky", justifyContent: "center" }}>
          <button style={styles.forgetAll} onClick={handleForgetAll}>
            <SvgClose fill={Colors.LIGHT.grey500} />
            <TextFont type="bold" size={18} weight="700" color={Colors.LIGHT.grey500}>
              <FormattedMessage id="hideAll" />
            </TextFont>
          </button>
        </div>
      </div>

      <RenderWithCondition condition={type === "transferred"}>
        <Prompting keySetting="isPromptTransfer" kind="transfer" />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} id={id} outModalClose={() => close()} />
      </ModalRenderWithCondition>
    </ModalUI>
  );
});

const styles = {
  container: {
    paddingTop: "-5px",
    height: "90%",
  },
  wrapperFooter: {
    marginBottom: "200px",
  },
  footer: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "flex-start",
    marginTop: "20px",
    height: "45%",
  },
  pl: {
    paddingLeft: "10px",
  },
  headerTitle: {
    paddingBottom: "1px",
    paddingTop: "15px",
  },
  forgetAll: {
    borderRadius: "10px",
    backgroundColor: Colors.LIGHT.lighGrey,
    height: "56px",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
  },
};
