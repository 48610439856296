import React, { MouseEvent, Ref, useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable, DraggableProvided } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { useSubscribe } from "@hooks/useSubscribe";
import { ITask } from "@interfaces/businessGoals.interface";
import { TMainSections, TSingleSections, TTaskSubType } from "@interfaces/businessTask.interface";
import { TaskSubtypes } from "@store/businessTask";
import { selectScreenDay } from "@store/screenDay";
import { setOpenContextData } from "@store/screenDay/slice";
import { Spacer } from "@styles/styles";

import { Buttons, ButtonsAllTasks, ButtonsMoveTasks } from "./buttons";
import { additionalSections } from "./constants";
import { SingleSectionHeader, SectionHeader } from "./headers";
import { Item } from "./Item";

interface IProps {
  data: Record<string, any[]>;
  press: (id: string, subType?: TTaskSubType) => void;
  typeTasks?: "transferred" | "delegated" | "takenBack" | "all";
  onDragEnd?: (dragDropProps, objectKey: string) => void;
  showAll?: Record<TSingleSections, () => void>;
  sectionTitle?: (arg: string) => JSX.Element;
  footer?: JSX.Element;
  onScroll?: () => void;
  listInnerRef?: Ref<HTMLDivElement>;
  allowPressIcon?: boolean;
  forceAllowForget?: boolean;
}

export const SwipeListSections = ({
  data,
  press,
  typeTasks,
  showAll,
  sectionTitle,
  footer,
  onScroll,
  listInnerRef,
  onDragEnd,
  allowPressIcon = false,
  forceAllowForget,
}: IProps) => {
  const [actionsId, setActionsId] = useState("");
  const [pos, setPos] = useState({ left: 0, top: 0 });
  const [viewSectionHeader, setViewSectionHeader] = useState<TMainSections | undefined>(undefined);

  const dispatch = useDispatch<any>();

  const { openContextData } = useSelector(selectScreenDay);

  const handleRightClick = async (e: MouseEvent<HTMLButtonElement>, taskId: string, taskSubType: TaskSubtypes, isFromOneS: boolean) => {
    e.preventDefault();

    setActionsId(taskId);
    setPos({ top: e.clientY, left: e.clientX });
    dispatch(setOpenContextData(null));
  };
  useEffect(() => {
    const handleCloseModal = () => {
      if (openContextData) {
        dispatch(setOpenContextData(null));
      }
      setActionsId("");
    };
    document.addEventListener("click", handleCloseModal);
    return () => document.removeEventListener("click", handleCloseModal);
  }, [openContextData]);

  useSubscribe(
    "closeOthersContextMenu",

    () => {
      setActionsId("");
    },
    [actionsId],
  );

  const renderItem = (data: { index: number; item: ITask; section: { title: string; data: ITask[] }; provided?: DraggableProvided }) => {
    const taskType = (() => {
      if (typeTasks) {
        return typeTasks;
      }

      return forceAllowForget ? "delegated" : data.section.title;
    })();

    return (
      <>
        <Item
          index={data.index}
          item={data.item}
          press={press}
          allowForget={forceAllowForget ?? additionalSections.includes(data.section.title as TSingleSections)}
          onRightClick={handleRightClick}
          isRightClick={actionsId === data.item.id}
          provided={data?.provided}
          allowPressIcon={allowPressIcon && !additionalSections.includes(data.section.title as TSingleSections)}
          sectionTitle={taskType}
        />
        {renderHiddenItem(data.section.title, { item: data.item, isVisible: actionsId === data.item.id, pos })}
      </>
    );
  };

  const renderHiddenItem = (title: string, propsButton) => {
    if (propsButton.item.status === "PAUSED") {
      return <ButtonsAllTasks {...propsButton} />;
    }
    if (propsButton.item.isFromOneS) {
      return <ButtonsAllTasks {...propsButton} />;
    }

    switch (typeTasks || title) {
      case "transferred":
        return <ButtonsMoveTasks {...propsButton} />;
      case "delegated":
      case "all":
        return <ButtonsAllTasks {...propsButton} />;
      default:
        return <Buttons {...propsButton} />;
    }
  };

  const renderSectionHeader = (title) => {
    if (sectionTitle) return sectionTitle(title);

    if (additionalSections.includes(title) && showAll) {
      return <SingleSectionHeader type={title} show={showAll[title as TSingleSections]} />;
    }
    return <SectionHeader setView={setViewSectionHeader} view={viewSectionHeader} section={title as TMainSections} />;
  };

  return (
    <StContentWrapSection
      onScroll={onScroll}
      ref={listInnerRef}
      onMouseLeave={() => setViewSectionHeader(undefined)}
      onMouseMove={() => setViewSectionHeader(undefined)}
    >
      {Object.keys(data).map((keyList) => (
        <RenderWithCondition key={keyList} condition={data[keyList]?.length || keyList === "important"}>
          {onDragEnd && keyList !== "transferred" ? (
            <>
              <DragDropContext onDragEnd={(props) => onDragEnd(props, keyList)}>
                <Spacer px={10} />
                <div>{renderSectionHeader(keyList)}</div>
                <Droppable droppableId={`droppable${keyList}`}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {data[keyList].map((element, index) => (
                        <Draggable key={element.id} draggableId={element.id} index={index}>
                          {(providedItem) =>
                            renderItem({
                              index,
                              item: element,
                              section: { title: keyList, data: data[keyList] },
                              provided: providedItem,
                            })
                          }
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : (
            <div>
              <Spacer px={10} />
              <div>{renderSectionHeader(keyList)}</div>
              {data[keyList].map((item, index) => renderItem({ index, item, section: { title: keyList, data: data[keyList] } }))}
            </div>
          )}
        </RenderWithCondition>
      ))}
      {footer}
    </StContentWrapSection>
  );
};

const StContentWrapSection = styled.section`
  height: 100%;
  overflow-y: auto;
`;
