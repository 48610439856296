import { Client } from "@stomp/stompjs";
import { debounce } from "lodash";
import addNotification from "react-push-notification";

import { updateTokens } from "@api/httpClient";
import { ACCESS_TOKEN } from "@constants/settings";
import { api, StoreTagTypes } from "@services/api";
import emitter from "@services/emitter";
import { getUnreadCountMessages } from "@store/user";
import { DelayTimer } from "@utils/progressiveDelay";
import { updateScreens } from "@utils/updateScreens";

import { store } from "./store";
import { secureStore } from "./utils";

export const delayTimer = new DelayTimer();

type TRemoteMessageData = { link: string; messageId: string; title: string; body: string };

const subscriptions = new Set<string>();

let currentUserID = "";
let clickedByCloseButton = false;
let assistantMode = false;
let isLoggedIn = false;
let disableMeetingsReset = false;
let client: Client = null;

const getAuthHeader = () => {
  try {
    return `Bearer ${secureStore.getValue(ACCESS_TOKEN)}`;
  } catch (error) {
    console.error("Failed to get token");
  }
};

const delayedConnectWebsocket = (delay = 10000) =>
  debounce(
    () => {
      connectWebSocket();
    },
    delay,
    { leading: false, trailing: true }, // Only execute at the end of the delay
  )();

const decodeJwtToken = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null; // Handle invalid token format
  }
};

const removeSubscriptionData = (topic: string) => {
  if (subscriptions.has(topic)) {
    subscriptions.delete(topic);
  }
};

const subscribeUser = (client: Client): Client => {
  if (!client || !client?.connected || subscriptions.has(currentUserID)) return;

  subscriptions.add(currentUserID);

  client.subscribe(`/topic/${currentUserID}/notifications`, (message) => {
    let url;
    const data = JSON.parse(message.body);

    if (data?.link?.endsWith("revoke")) {
      emitter.emit("updateMeetings");
      store.dispatch(
        api.util.invalidateTags([
          { type: StoreTagTypes.Meet, id: "LIST" },
          { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
        ]),
      );
      return;
    }

    const isTaskUpdate = data?.link?.includes("refresh/tasks");
    if (isTaskUpdate) {
      emitter.emit("updateTasks");
      return;
    }

    const isMeetingUpdate = data?.link?.includes("refresh/meetings");

    if (isMeetingUpdate) {
      emitter.emit("updateMeetings");

      if (!disableMeetingsReset) {
        store.dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
        return;
      }

      store.dispatch(
        api.util.invalidateTags([
          { type: StoreTagTypes.Meet, id: "LIST" },
          { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
        ]),
      );

      return;
    }

    const { title } = data as TRemoteMessageData;
    const isCancelMeetPush = title === "Отменена встреча";
    let type = "";

    if (data) {
      type = data?.link
        ?.slice(data?.link.indexOf("//") + 2)
        .split("/")
        .slice(-2)[0];
    }

    if ((type === "meeting" || isCancelMeetPush) && !disableMeetingsReset) {
      store.dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
    }

    const getNotificationData = () => ({
      title: data.title,
      duration: 20000,
      message: data.message,
      backgroundTop: assistantMode ? "#453831" : "#274335",
      backgroundBottom: assistantMode ? "#795929" : "#297952",
      onClick: () => {
        if (!clickedByCloseButton) {
          window.location.href = url.toString();
        }
        clickedByCloseButton = false;
      },
    });

    if (!data.link) {
      addNotification({
        ...getNotificationData(),
        closeButton: <div onClick={() => (clickedByCloseButton = true)}>Закрыть</div>,
        native: false,
      });

      addNotification({
        ...getNotificationData(),
        native: true,
      });

      store.dispatch(getUnreadCountMessages());
      return;
    }

    const [linkType, objectId] = data.link
      .slice(data.link.indexOf("//") + 2)
      .split("/")
      .slice(-2);

    if (linkType === "profile") {
      url = new URL(`https://${window.location.host}/profile`);
      url.searchParams.append("tab", objectId);
    } else {
      url = new URL(`https://${window.location.host}`);
      url.searchParams.append(linkType, objectId);
    }

    addNotification({
      ...getNotificationData(),
      closeButton: <div onClick={() => (clickedByCloseButton = true)}>Закрыть</div>,
      native: false,
    });

    addNotification({
      ...getNotificationData(),
      native: true,
    });

    store.dispatch(getUnreadCountMessages());
    updateScreens(store, linkType);
  });
};

async function connectWebSocket() {
  const token = getAuthHeader();

  client = new Client({
    brokerURL: process.env.REACT_APP_BASE_SOCKET_PUSH_URL,
    reconnectDelay: 5000,
    connectHeaders: {
      Authorization: token,
    },
    onConnect: () => {
      if (currentUserID) {
        subscribeUser(client);
      }

      delayTimer.reset();
    },
    onStompError: async (frame) => {
      console.error(`Broker reported error: ${frame.headers.message}`);
      console.error(`Additional details: ${frame.body}`);

      try {
        if (isLoggedIn && frame.headers["message"].includes("Access Denied")) {
          await updateTokens();
          removeSubscriptionData(currentUserID);

          if (client) {
            await client.deactivate();
          }

          delayedConnectWebsocket(delayTimer.getNextDelay());
        }
      } catch (e) {
        console.error("Failed to reconnect", e);
      }
    },
    onWebSocketError: (e) => {
      console.error("WebSocket encountered an error:", e);
    },
    onWebSocketClose: async (e) => {
      try {
        removeSubscriptionData(currentUserID);

        // disable stomp auto-reconnect loop
        if (client) {
          await client.deactivate();
        }

        if (!isLoggedIn) return;

        const header = getAuthHeader();

        if (header) {
          // Decode the token to check the expiration time
          const token = header.split(" ")[1]; // Assuming the header is in the format 'Bearer <token>'
          const decodedToken = decodeJwtToken(token); // Implement this function to decode your token
          const tokenExpirationTime = (decodedToken?.exp ?? 0) * 1000; // Convert to milliseconds
          const currentTime = Date.now();
          const remainingTime = tokenExpirationTime - currentTime;

          if (remainingTime < 10000) {
            // If less than 10 seconds remaining
            await updateTokens();
          }

          delayedConnectWebsocket(delayTimer.getNextDelay());
        }
      } catch (error) {
        console.error("Failed to properly handle websocket close event", error);
      }
    },
  });

  client.activate();
}

window.addEventListener("online", () => {
  emitter.emit("backOnline");
});

window.addEventListener("load", async () => {
  try {
    if (isLoggedIn) {
      if (client) {
        await client.deactivate();
      }

      connectWebSocket();
    }
  } catch {}

  emitter.addListener("get_user_id", (userId) => {
    currentUserID = userId;
  });

  emitter.addListener("login", async () => {
    isLoggedIn = true;

    if (client) {
      await client.deactivate();
    }

    delayedConnectWebsocket(500);
  });

  emitter.addListener("logout", () => {
    isLoggedIn = false;

    if (client) {
      client.deactivate();
    }
  });

  emitter.addListener("backOnline", async () => {
    if (client) {
      await client.deactivate();
      delayTimer.reset();
    }
  });

  emitter.addListener("change_listener_user", async (userId, isAssistantMode) => {
    try {
      assistantMode = isAssistantMode;

      if (!userId || currentUserID === userId) return;

      if (client) {
        await client.deactivate();
        delayTimer.reset();
      }

      currentUserID = userId;
    } catch (e) {
      console.error("STOMP ERROR:", e);
    }
  });

  emitter.addListener("disableMeetingsReset", (value: boolean) => {
    disableMeetingsReset = value;
  });
});
