import { TNotificationDurations, TNotificationName, TNotificationTypes } from "./meet.interface";

export enum NotificationDurations {
  NOW = "NOW",
  ONE_MINUTE = "ONE_MINUTE",
  FIVE_MINUTES = "FIVE_MINUTES",
  TEN_MINUTES = "TEN_MINUTES",
  FIFTEEN_MINUTES = "FIFTEEN_MINUTES",
  THIRTY_MINUTES = "THIRTY_MINUTES",
  ONE_HOUR = "ONE_HOUR",
  TWELVE_HOURS = "TWELVE_HOURS",
  TWENTY_FOR_HOURS = "TWENTY_FOR_HOURS",
  ONE_DAY = "ONE_DAY",
}
export const dataTextDurations = {
  NOW: "atStartOfMeeting",
  ONE_MINUTE: "in1minute",
  FIVE_MINUTES: "in5minute",
  TEN_MINUTES: "in10minute",
  FIFTEEN_MINUTES: "in15minutes",
  THIRTY_MINUTES: "in30minutes",
  ONE_HOUR: "in1hour",
  TWELVE_HOURS: "in12hours",
  TWENTY_FOR_HOURS: "in24hours",
  ONE_DAY: "in24hours",
};

export enum NotificationTypes {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
  WEB_SOCKET = "WEB_SOCKET",
}
export enum NotificationPeriod {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
}
export enum NotificationName {
  CONFIRM_MEETING_PARTICIPANT_STATUS = "CONFIRM_MEETING_PARTICIPANT_STATUS",
  MEETING_STATUS_COMMENT = "MEETING_STATUS_COMMENT",
}

export interface IEventsDisplaySettings {
  isShowBusiness: boolean;
  isShowPersonal: boolean;
}
export interface IMapReminderSettings {
  textDuration: string;
  valueDuration: TNotificationDurations;
  type: TNotificationTypes;
}
export interface IDisableNotifications {
  notificationName: TNotificationName;
  notificationTypes: TNotificationTypes[];
}
export interface IUserNotificationSettings {
  notificationType: TNotificationTypes;
  sendingTimes: TNotificationDurations[];
}

export interface IPersonalSettings {
  showBusinessTasks: boolean;
  deleteNotificationsReminderMeetingAfterCompleted: boolean;
  showPersonalTasks: boolean;
  oneSTasksSync: boolean;
  showOneSTasks: boolean;
  showBusinessEvents: boolean;
  showPersonalEvents: boolean;
  enableUserNotificationSettings: boolean;
  reminderNotificationSendingTimeSettings: IUserNotificationSettings[];
  videoConferencePermanentUrl: string;
}
