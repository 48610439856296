import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { TypePermission } from "@interfaces/user.interface";

import { RootState } from "../store";

import { IState } from "./slice";

// eslint-disable-next-line react-hooks/rules-of-hooks
export const userSelector = () => useSelector((state: RootState) => state.user) as IState;

export const selectUserPermissoins = createSelector(
  (state: RootState) => state.user.currentUser,
  (user) => {
    const obj = {} as Record<TypePermission, boolean>;

    if (!user) return obj;

    [...user.permissions].map((i) => (obj[i] = true));

    return obj;
  },
);

export const selectIsDogmaUser = createSelector(
  (state: RootState) => state.user.currentUser,
  (user) => user?.roles?.includes("DOGMA_USER"),
);

export const selectUserForAssistant = createSelector(
  (state: RootState) => state.user,
  (user) =>
    user.directorId
      ? { ...user.managers.filter((i) => i.id === user.directorId)[0], roles: ["DOGMA_USER"], permissions: [] }
      : user.currentUser,
);

export const selectIsAssistant = createSelector(
  (state: RootState) => state.user,
  (user) => !!user?.managers?.length,
);

export const selectIsModeAssistant = createSelector(
  (state: RootState) => state.user,
  (user) => !!user?.directorId,
);
