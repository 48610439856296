import React, { ChangeEvent, MouseEvent, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { TIcon } from "@screens/day/components/ListTasks/taskItem/icon/config";
import { workDayComplete, forgetWorkDay, workDayCancelComplete, TaskSubtypes } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserPermissoins } from "@store/user";
import { showToast } from "@utils/toast";
import { toFormatDate } from "@utils/toFormatTime";

type TUseInfoTask = (task: ITask) => {
  workDay: WorkDay | undefined;
  isCompleted: boolean;
  status: TIcon;
  dateNow: Dayjs;
  handleCheckBox: (e: ChangeEvent<HTMLButtonElement>) => void;
  handleForget: (e: MouseEvent<HTMLButtonElement>, arg?: string) => void;
};

export const useInfoTask: TUseInfoTask = (task: ITask) => {
  const name = useLocation().pathname;
  const dispatch = useDispatch<any>();

  const dateNow = useSelector(selectCurrentDate);
  const permissions = useSelector(selectUserPermissoins);

  const isPersonalTask = task.subType === TaskSubtypes.Personal;

  const handleCheckBox = async (e: ChangeEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (name === "/tasks") return;

    if (dateNow.isAfter(dayjs(), "date")) {
      return showToast("futureActionUnavailable");
    }
    if (status === "dealWith") {
      return await dispatch(workDayCancelComplete({ id: task.id, date: toFormatDate(dateNow), isPersonalTask }));
    }
    return await dispatch(workDayComplete({ id: task.id, date: toFormatDate(dateNow), isPersonalTask }));
  };

  const handleForget = async (e: MouseEvent<HTMLButtonElement>, type?: string) => {
    e.stopPropagation();
    const isDelegatedTask = task?.delegatedFromUser?.id ? "delegated" : undefined;

    await dispatch(forgetWorkDay({ id: task.id, isPersonalTask, type: type ?? isDelegatedTask }));
  };

  const workDay = useMemo(() => task.workDays?.filter((i) => i.date === dateNow.format("YYYY-MM-DD"))[0], [task.workDays, dateNow]);

  const isCompleted = useMemo(() => task.status === "COMPLETED", [task.status]);

  const status: TIcon = useMemo(() => {
    if (workDay?.isCompleted && (name === "/" || name === "/analysis")) {
      return "dealWith";
    } else if (workDay?.status === "NOT_COMPLETED" && name === "/") {
      return "notDealWith";
    } else if (task.delegatedToUser && permissions.CAN_DELEGATE) {
      return "delegate";
    } else if (task.delegatedFromUser) {
      return "delegateFrom";
    }
    return "default";
  }, [workDay?.isCompleted, workDay?.status, name, task.delegatedToUser, task.delegatedFromUser, permissions.CAN_DELEGATE]);

  return useMemo(() => ({ workDay, isCompleted, status, dateNow, handleCheckBox, handleForget }), [workDay, isCompleted, status, dateNow]);
};
