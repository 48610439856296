import React, { FC, useState } from "react";

import { TextFont } from "../TextFont";

import { NotificationButton } from "./NotificationButton";
import { NotificationUI } from "./NotificationUI";

interface IModalError {
  phrase?: string;
}

const defaultPhrase = "Добавить цель в прошлый месяц нельзя";

export const NotificationError: FC<IModalError> = ({ phrase = defaultPhrase }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <NotificationUI isVisible={isVisible} style={styles.modalWrapper}>
      <section style={styles.modal}>
        <TextFont style={styles.text}>{phrase}</TextFont>
        <div style={styles.button}>
          <NotificationButton setIsVisible={setIsVisible}>
            <TextFont style={styles.buttonText}>Ок</TextFont>
          </NotificationButton>
        </div>
      </section>
    </NotificationUI>
  );
};

const styles = {
  modalWrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "rgba(242, 242, 242, 0.8)",
    borderRadius: "14px",
    width: "270px",
  },
  text: {
    "text-align": "center",
    fontSize: "18px",
    color: "#000000",
    padding: "16px",
  },
  button: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: "0.5px",
    borderTopColor: "rgba(60, 60, 67, 0.36)",
    fontWeight: "700px",
  },
  buttonText: {
    color: "#297952",
    fontWeight: "700",
    fontSize: "18px",
  },
};
