import globalState from "@utils/globalState";

export const styles = {
  main: {
    display: "flex",
    "flex-direction": "column",
    gap: 20,
    padding: "12px",
    paddingTop: "10px",
    height: "90%",
  },
  dateBlock: {
    display: "flex",
  },
  wrapperBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },
  dateText: {
    fontSize: "16px",
    fontWeight: "700",
    marginLeft: "5px",
  },
  textThin: {
    fontSize: "16px",
    fontWeight: "normal",
  },
  input: {
    backgroundColor: globalState.colorSchema.white,
    borderRadius: "9px",
    padding: "16px",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "center",
  },
  buttonText: {
    fontSize: "18px",
    fontWeight: "700",
    margin: "12px",
    color: globalState.colorSchema.white,
  },
  buttonSave: {
    marginTop: "15px",
  },
};
