import React, { FC, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import Toggle from "react-toggle";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@components/hoc";
import { TNotificationDurations } from "@interfaces/meet.interface";
import { updatePersonalSettings } from "@store/userSettings/api";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";

import {
  IMapReminderSettings,
  IUserNotificationSettings,
  NotificationTypes,
  dataTextDurations,
} from "../../../../../../interfaces/userSettings.interface";

import { ControlBlockReminder } from "./ControlBlockReminder";
import { DuplicateNotify } from "./DuplicateNotify";

interface IRemindMeetings {
  notificationSettings: IUserNotificationSettings[];
  currentId?: string;
  isToggleEnabled: boolean;
  onToggleChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MeetingsReminder: FC<IRemindMeetings> = ({ notificationSettings, currentId, isToggleEnabled, onToggleChange }) => {
  const [activeRemind, setActiveRemind] = useState(!!notificationSettings?.length);
  const [openControlReminder, setOpenControlReminder] = useState(false);
  const [openDuplicateNotify, setDuplicateNotify] = useState(false);

  const dispatch = useDispatch();

  const currentReminderList: IMapReminderSettings[] = useMemo(() => {
    const listResult = [] as IMapReminderSettings[];
    const listDurationsOfPush = notificationSettings?.filter((item) => item?.notificationType === NotificationTypes.PUSH)[0]?.sendingTimes;
    listDurationsOfPush?.forEach((item) => {
      listResult.push({ textDuration: dataTextDurations[item], valueDuration: item, type: NotificationTypes.PUSH });
    });
    const listDurationsOfEmail = notificationSettings?.filter((item) => item?.notificationType === NotificationTypes.EMAIL)[0]
      ?.sendingTimes;
    listDurationsOfEmail?.forEach((item) => {
      listResult.push({ textDuration: dataTextDurations[item], valueDuration: item, type: NotificationTypes.EMAIL });
    });

    return listResult;
  }, [notificationSettings]);

  function handleDeleteReminder(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: IMapReminderSettings) {
    e.stopPropagation();
    let currentPushDurations: TNotificationDurations[];
    const deletedReminder = notificationSettings.map((item) => {
      if (item.notificationType === NotificationTypes.PUSH) {
        currentPushDurations = item.sendingTimes.filter((item) => item !== data.valueDuration);
      }
      return item.notificationType !== data.type
        ? item
        : {
            ...item,
            sendingTimes: item.sendingTimes.filter((item) => item !== data.valueDuration),
          };
    });
    const correctedWebSocketValue = deletedReminder?.map((item) =>
      item.notificationType !== NotificationTypes.WEB_SOCKET
        ? item
        : {
            ...item,
            sendingTimes: currentPushDurations,
          },
    );

    const requestData = {
      reminderNotificationSendingTimeSettings: correctedWebSocketValue.filter((item) => item.sendingTimes?.length),
    };
    dispatch(updatePersonalSettings({ id: currentId, body: requestData }));
  }

  return (
    <StWrapperDiv>
      <StLineCheckBoxDiv>
        <TextFont weight="700" size={16}>
          <FormattedMessage id="remindAboutMeetings" defaultMessage="Напоминать о встречах" />
        </TextFont>
        <Toggle
          checked={isToggleEnabled}
          onChange={onToggleChange}
          icons={false}
          colorOn={Colors.LIGHT.greenHover}
          colorOff={Colors.LIGHT.lighGrey}
          textColorOff={Colors.LIGHT.white}
          style={{ span: { background: Colors.LIGHT.white } }}
        />
      </StLineCheckBoxDiv>
      <StAccordBlockDiv open={isToggleEnabled} large={currentReminderList?.length > 2}>
        <TextFont weight="700" size={16}>
          <FormattedMessage id="reminder" defaultMessage="Напоминание" />
        </TextFont>
        {currentReminderList.map((item) => (
          <StLineInfoReminder key={uuidv4()}>
            <TextFont size={18} lineHeight={26}>
              <FormattedMessage id={item.textDuration} />{" "}
              <FormattedMessage id={item.type === NotificationTypes.PUSH ? "notification" : "letterToMail"} defaultMessage="уведомление" />
            </TextFont>
            <button
              onClick={(e) => {
                handleDeleteReminder(e, item);
              }}
            >
              <SvgRemove fill={Colors.LIGHT.background.grey} />
            </button>
          </StLineInfoReminder>
        ))}
        <RenderWithCondition condition={currentReminderList.length < 6}>
          <StLineCheckBoxDiv as="button" onClick={() => setOpenControlReminder(true)}>
            <TextFont size={16} lineHeight={24} color={Colors.LIGHT.background.green}>
              + <FormattedMessage id="reminderAdd" defaultMessage="Добавить напоминание" />
            </TextFont>
            <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
          </StLineCheckBoxDiv>
        </RenderWithCondition>
      </StAccordBlockDiv>
      <ControlBlockReminder
        isOpen={openControlReminder}
        onCancel={() => setOpenControlReminder(false)}
        currentId={currentId}
        dataSettings={notificationSettings}
        openNotify={() => setDuplicateNotify(true)}
      />
      <DuplicateNotify isOpen={openDuplicateNotify} onCancel={() => setDuplicateNotify(false)} />
    </StWrapperDiv>
  );
};

const StWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
`;
const StLineCheckBoxDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  background-color: ${() => Colors.LIGHT.white};
  padding: 12px;
  border-radius: 10px;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;

const StLineInfoReminder = styled(StLineCheckBoxDiv)`
  border-radius: 6px;
  height: 50px;
`;

const StAccordBlockDiv = styled.div<{ open: boolean; large?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 9px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease;
  ${({ open, large }) =>
    open &&
    `
    max-height: ${large ? "380px" : "200px"};
    transition: max-height 0.5s ease;
   }
  `}
`;
