import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { linkPrivacyPolicy, linkTermsOfUse } from "@constants/settings";
import emitter from "@services/emitter";
import { registerApi } from "@store/auth/api";
import { RootState } from "@store/index";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/isTablet";

import logo from "./assets/logo.png";

export const Registration = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [firstName, setFirtsName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      emitter.emit("login");

      return navigate("/");
    }
  }, [isLoggedIn]);

  const onSubmit = () => {
    dispatch(registerApi({ firstName, middleName, lastName }));
  };

  const phFirstName = useIntl().formatMessage({ id: "firstName" });
  const phLastName = useIntl().formatMessage({ id: "lastName" });
  const phMiddleName = useIntl().formatMessage({ id: "middleName" });

  return (
    <div className="container" style={styles.container}>
      <div style={isTablet && { paddingTop: 0 }}>
        <div style={styles.logo}>
          <img src={logo} width={"146px"} />
        </div>

        <TextFont style={styles.text}>
          <FormattedMessage id="enterYourFullName" />
        </TextFont>

        <div style={styles.code}>
          <input value={lastName} placeholder={phLastName} onChange={(e) => setLastName(e.target.value)} style={styles.input} />
          <input value={firstName} placeholder={phFirstName} onChange={(e) => setFirtsName(e.target.value)} style={styles.input} />
          <input value={middleName} placeholder={phMiddleName} onChange={(e) => setMiddleName(e.target.value)} style={styles.input} />
        </div>

        <button style={styles.button} onClick={onSubmit}>
          <TextFont type="bold" style={{ color: "#fff", fontSize: 18 }}>
            <FormattedMessage id="next" />
          </TextFont>
        </button>
      </div>

      <div style={styles.wrapLink}>
        <TextFont style={{ color: "#A3A3A3", textAlign: "center" }}>
          <FormattedMessage id="privacy" />
        </TextFont>
        <a href={linkPrivacyPolicy} target="_blank">
          <TextFont style={styles.link}>
            <FormattedMessage id="privacy1" />
          </TextFont>
        </a>
        <TextFont style={{ color: "#A3A3A3", display: "inline-block" }}>и</TextFont>
        <a href={linkTermsOfUse} target="_blank">
          <TextFont style={styles.link}>
            <FormattedMessage id="privacy2" />
          </TextFont>
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F5F9F8",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: isTablet ? "30px" : 0,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
  },
  text: {
    "text-align": "center",
    lineHeight: "24px",
    fontSize: "18px",
  },
  code: {
    display: "flex",
    "flex-direction": "column",
    marginBottom: "30px",
    marginTop: "30px",
  },
  input: {
    fontSize: "18px",
    fontWeight: "400",
    borderRadius: "10px",
    border: "1px #B3BEB8 solid",
    paddingLeft: "10px",
    paddingTop: "16px",
    paddingBottom: "16px",
    marginBottom: "10px",
    backgroundColor: "#fff",
  },
  button: {
    width: "100%",
    backgroundColor: "#297952",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    height: "56px",
  },
  wrapLink: {
    display: "flex",
    "flex-wrap": "wrap",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "400",
    padding: "0 21px",
    marginBottom: "50px",
    gap: 5,
  },
  link: {
    color: globalState.colorSchema.green,
  },
};
