import { useState, useLayoutEffect, useMemo, FC } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RenderWithCondition } from "@hoc";
import { IMessage } from "@interfaces/user.interface";
import { viewMessage } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";
import openDeepLink from "@utils/openDeepLink";

const LineHeight = 24;

interface IProps {
  message: IMessage;
  onClose: () => void;
  handleRightClick: (e: any, taskId: string) => void;
}

export const Message: FC<IProps> = ({ message, onClose, handleRightClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDropdown, setIsDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    const elementText = document.getElementById("text");
    if (elementText) {
      const textLines = elementText.offsetHeight / LineHeight;
      setIsDropdown(textLines > 3);
    }
  }, []);

  const handlePress = () => {
    if (!message.viewed) {
      dispatch(viewMessage(message.id));
    }

    if (message.link) {
      openDeepLink(message.link, dispatch, navigate);
      onClose();
    }
  };

  const handleArrow = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <button
      key={message.id}
      onContextMenu={(e) => handleRightClick(e, message.id)}
      style={{ ...styles.block, backgroundColor: message.viewed ? "white" : Colors.LIGHT.background.main }}
      onClick={handlePress}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", marginRight: 12, flex: 0.05 }}>
          <div style={{ ...styles.circle, backgroundColor: message.viewed ? Colors.LIGHT.grey : Colors.LIGHT.red }} />
        </div>
        <div style={{ width: "85%", flex: isDropdown ? 0.8 : 0.95 }}>
          <TextFont size={18} weight="700" type="bold">
            {message.subject}
          </TextFont>
          <TextFont
            id="text"
            style={{ lineHeight: `${LineHeight}px`, ...(isDropdown && { height: isOpen ? "auto" : LineHeight * 3, overflow: "hidden" }) }}
            size={16}
          >
            {message.content}
          </TextFont>

          <TextFont style={styles.date}>{dayjs(message.creationTime).format("DD.MM.YYYY HH:mm")}</TextFont>
        </div>
        <RenderWithCondition condition={isDropdown}>
          <button style={{ marginTop: 15 }} onClick={handleArrow}>
            <ArrowSvg type={isOpen ? "up" : "down"} fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
          </button>
        </RenderWithCondition>
      </div>
    </button>
  );
};

const styles = {
  block: {
    borderRadius: 11,
    padding: 12,
    width: "100%",
    borderBottom: "1px solid #E3E9E7",
  },
  additionalBlock: {
    width: "6%",
    alignItems: "center",
    justifyContent: "center",
    marginRight: -4,
    marginTop: -4,
    flex: 0.15,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 8,
  },
  date: {
    marginTop: 4,
    color: Colors.LIGHT.placeholder.main,
  },
};
