import React, { FC, memo, useMemo, MouseEvent, useState, ChangeEvent } from "react";

import dayjs from "dayjs";
import { DraggableProvided } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as CloseSvg } from "@assets/close-thin.svg";
import { ReactComponent as SvgDeadline } from "@assets/deadline.svg";
import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ReactComponent as SvgOneSDelegated } from "@assets/one-s-delegated.svg";
import { ReactComponent as SvgOneS } from "@assets/one-s.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { useInfoTask } from "@hooks/useInfoTask";
import { ITask } from "@interfaces/businessGoals.interface";
import { TTaskSubType } from "@interfaces/businessTask.interface";
import { IStateUser } from "@interfaces/user.interface";
import { TaskSubtypes, selectBusinessTasks } from "@store/businessTask";
import { selectTheme } from "@store/theme";
import { selectUserPermissoins } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { Checkbox } from "@ui";
import { bgItem } from "@ui/taskActions/config";
import { arePropsEqual } from "@utils/index";
import { toFormatHHmm } from "@utils/toFormatTime";

import { Icon } from "./icon/Icon";

interface IItem {
  index: number;
  item: ITask;
  press: (id: string, subType?: TTaskSubType) => void;
  bg?: string;
  isLast?: boolean;
  allowForget?: boolean;
  onRightClick: (arg1: MouseEvent<HTMLButtonElement>, arg2: string, arg3: TaskSubtypes, isFromOneS: boolean) => void;
  checkbox?: boolean;
  isChecked?: boolean;
  allowDeletion?: boolean;
  meetingTaskStyles?: boolean;
  onDelete?: () => void;
  handleCheckedTask?: (id: ITask) => void;
  allowPressIcon?: boolean;
  sectionTitle?: string;
  isRightClick?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
  provided?: DraggableProvided;
}

export const Item: FC<IItem> = memo(function Item({
  index,
  item,
  press,
  bg,
  isLast = false,
  onRightClick,
  allowForget = false,
  checkbox = false,
  isChecked = false,
  allowDeletion = false,
  meetingTaskStyles = false,
  onDelete,
  handleCheckedTask,
  allowPressIcon,
  sectionTitle,
  provided,
  ...props
}) {
  const name = useLocation().pathname;
  const colorSchema = selectTheme("extra");
  const innerRef = provided?.innerRef;

  const { isCompleted, workDay, status, handleForget, handleCheckBox } = useInfoTask(item);
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isChecked);

  const isPersonalTask = item.subType === TaskSubtypes.Personal;
  const isFromOneS = item.isFromOneS ?? false;

  const { currentTask } = useSelector(selectBusinessTasks);
  const permissions = useSelector(selectUserPermissoins);

  const handlePressIcon = (e: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLButtonElement>) => {
    if (allowPressIcon && !isFromOneS) {
      handleCheckBox(e as ChangeEvent<HTMLButtonElement>);
    }
    if (allowPressIcon && isFromOneS) {
      e.stopPropagation();

      if (item.workDays.length === 1) {
        onRightClick(e as MouseEvent<HTMLButtonElement>, item.id, item.subType, isFromOneS);
      }
      if (item.workDays.length > 1) {
        handleCheckBox(e as ChangeEvent<HTMLButtonElement>);
      }
    }
  };

  const handleTaskCheckBox = (event: React.MouseEvent<HTMLButtonElement>, item: ITask) => {
    event.stopPropagation();
    setIsItemChecked(!isItemChecked);
    handleCheckedTask && handleCheckedTask(item);
  };

  const handleDeleteWrapper = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  const isDeadlineOut = useMemo(() => item.plannedEndDate && dayjs().isAfter(item.plannedEndDate, "day"), [item.plannedEndDate]);

  const generateDate = useMemo(() => {
    const color = isDeadlineOut ? colorSchema.white : colorSchema.text.grey400;

    let text = "";

    if (item.plannedEndDate) {
      text = dayjs(item.plannedEndDate).format("DD.MM");
    } else if (workDay?.startTime && workDay.endTime) {
      text = `${toFormatHHmm(dayjs(workDay?.startTime))}-${toFormatHHmm(dayjs(workDay?.endTime))}`;
    }

    if (!text) return "";

    return (
      <StLineDateDiv isDeadline={!!isDeadlineOut}>
        {item.plannedEndDate ? <SvgDeadline fill={isDeadlineOut ? colorSchema.white : colorSchema.orange} /> : ""}
        <TextFont color={color}>{text}</TextFont>
      </StLineDateDiv>
    );
  }, [item.timeEnabled, item.plannedEndDate, workDay?.startTime, workDay?.endTime, isDeadlineOut]);

  const textDelegate = (delegated: IStateUser | null) =>
    `${delegated?.lastName} ${delegated?.firstName ? `${delegated?.firstName.slice(0, 1)}.` : ""}`;

  const colorTitle = useMemo(
    () => (isCompleted || status === "dealWith" ? colorSchema.grey : colorSchema.text.main),
    [isCompleted, status],
  );

  const projectName = useMemo(() => {
    const shortName = item.project?.shortName ? `${item.project?.shortName} ` : "";
    if (shortName) return shortName;

    const projectName = item.project?.name;
    if (projectName?.length > 21) {
      return `${projectName.slice(0, 3)}...${projectName.slice(projectName.length - (generateDate ? 30 : 40), projectName.length)}`;
    }
    return projectName;
  }, [item.project?.shortName, item.project?.name, generateDate]);

  const containerStyles = {
    ...(isLast && { marginBottom: 100 }),
    ...(!!bg && { backgroundColor: bg }),
    ...(item.id === currentTask?.id && name === "/tasks" && { backgroundColor: colorSchema.white }),
    ...(name === "/analysis" &&
      !item.unplanned &&
      workDay &&
      bgItem[workDay.status] &&
      workDay && { backgroundColor: bgItem[workDay.status] }),
    ...(isPersonalTask && { backgroundColor: colorSchema.lightPurple, opacity: 1 }),
    ...(props.isRightClick && { backgroundColor: colorSchema.lighGrey }),
  };

  const isSpecialTask = !!(item.delegatedToUser || item.project || item.plannedEndDate || item.tag);

  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  return (
    <StContentWrapDiv
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={innerRef}
      isFirst={index === 0 && !meetingTaskStyles}
      isMeetTaskStyle={meetingTaskStyles}
      isPointer={!innerRef && !provided}
      style={{ ...containerStyles, ...(provided && getItemStyle(provided?.draggableProps?.style)) }}
      onClick={() => press(item.id, item.subType)}
      onContextMenu={(e) => onRightClick(e as unknown as MouseEvent<HTMLButtonElement>, item.id, item.subType, item.isFromOneS)}
    >
      {checkbox ? (
        <Checkbox
          isChecked={isItemChecked}
          onChange={(e) => handleTaskCheckBox(e as MouseEvent<HTMLButtonElement>, item)}
          style={{ marginLeft: 10 }}
        />
      ) : (
        <Icon type={item.priority} status={status} extraIcon={item.status} press={handlePressIcon} />
      )}

      <div>
        <StContentDiv>
          <StBlockTaskNameDiv isSpecialTask={isSpecialTask} isExistTaskStyles={meetingTaskStyles}>
            <RenderWithCondition condition={item.tag?.name}>
              <TextFont color={colorTitle} size={16} weight="700">
                {`${item.tag?.name} `}
              </TextFont>
            </RenderWithCondition>
            <StTextTwoLinesTFont color={colorTitle} size={16}>
              {item.name}
            </StTextTwoLinesTFont>
          </StBlockTaskNameDiv>

          <RenderWithCondition condition={allowForget}>
            <StBlockIconsDiv>
              <StButtonRemoveBtn onClick={(e) => handleForget(e, sectionTitle)}>
                <SvgRemove fill={Colors.LIGHT.placeholder.main} width={10} height={10} />
              </StButtonRemoveBtn>
            </StBlockIconsDiv>
          </RenderWithCondition>
        </StContentDiv>

        <StBlockDelegateDiv>
          <RenderWithCondition condition={item.delegatedFromUser}>
            <StContentDelegateDiv>
              <StIconWrapDiv>
                <SvgTakeBack width={12} height={12} />
              </StIconWrapDiv>
              <RenderWithCondition condition={item.delegatedFromUser?.lastName}>
                <TextFont
                  color={item.status === "COMPLETED" ? colorSchema.purple100 : colorSchema.purple}
                  align="right"
                >{`от ${textDelegate(item.delegatedFromUser)}`}</TextFont>
              </RenderWithCondition>
            </StContentDelegateDiv>
          </RenderWithCondition>
          <RenderWithCondition condition={permissions.CAN_DELEGATE && item.delegatedToUser}>
            <StContentDelegateDiv>
              <StIconWrapDiv>
                <SvgDelegate width={12} height={12} />
              </StIconWrapDiv>
              <RenderWithCondition condition={item.delegatedToUser?.lastName}>
                <TextFont color={item.status === "COMPLETED" ? colorSchema.yellow100 : colorSchema.yellow}>{`на ${textDelegate(
                  item.delegatedToUser,
                )}`}</TextFont>
              </RenderWithCondition>
            </StContentDelegateDiv>
          </RenderWithCondition>
        </StBlockDelegateDiv>

        <StBlockMainDiv>
          <StBlockSubNameDiv>
            <RenderWithCondition condition={isFromOneS}>
              <SvgOneS />
            </RenderWithCondition>

            <RenderWithCondition condition={isFromOneS && item?.isDelegatedOneSTask}>
              <SvgOneSDelegated />
            </RenderWithCondition>

            {item.project ? (
              <StBlockProjectDiv isCompleted={isCompleted}>
                <StTextProjectTFont color={isCompleted ? colorSchema.grey : colorSchema.blue}>{projectName}</StTextProjectTFont>
              </StBlockProjectDiv>
            ) : (
              <div />
            )}
          </StBlockSubNameDiv>
          <StBlockDateDiv>
            <RenderWithCondition condition={generateDate}>
              <StDateWrapDiv>{generateDate}</StDateWrapDiv>
            </RenderWithCondition>
          </StBlockDateDiv>
        </StBlockMainDiv>
      </div>

      {allowDeletion && (
        <StButtonDeleteBtn
          onClick={(e) => {
            handleDeleteWrapper(e);
          }}
          generateDate={!!generateDate}
        >
          <CloseSvg scale={0.5} />
        </StButtonDeleteBtn>
      )}
    </StContentWrapDiv>
  );
},
arePropsEqual);

const StContentWrapDiv = styled.div<{ isPointer: boolean; isMeetTaskStyle: boolean; isFirst: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 33px 1fr auto;
  grid-gap: 10px;
  padding: 3px 5px 5px 5px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
  background-color: ${Colors.LIGHT.background.main};

  ${({ isPointer }) =>
    isPointer &&
    `
    cursor: pointer;
    `};
  ${({ isFirst }) =>
    isFirst &&
    `
    border-top: 1px solid ${Colors.LIGHT.lighGrey};
    `};
  ${({ isMeetTaskStyle }) =>
    isMeetTaskStyle &&
    `
    margin-bottom: 8px;
    border-bottom: 1px;
    border-radius:5px;
    padding: 5px 0;
    min-height: 48px;
    background-color: ${Colors.LIGHT.white};
    cursor: pointer;
    `};
`;
const StContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;
const StBlockTaskNameDiv = styled.div<{ isSpecialTask: boolean; isExistTaskStyles: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 90%;
  ${({ isSpecialTask }) =>
    isSpecialTask &&
    `
    margin-top: 1px;
    `};
  ${({ isExistTaskStyles }) =>
    isExistTaskStyles &&
    `
    margin-bottom: 0px;
    padding-top: 5px;
    `};
`;
const StTextTwoLinesTFont = styled(TextFont)`
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StBlockIconsDiv = styled.div`
  justify-content: flex-end;
`;
const StButtonRemoveBtn = styled.button`
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;
const StBlockDelegateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  gap: 10px;
`;
const StContentDelegateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;
const StIconWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StBlockMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  max-height: 24px;
`;
const StBlockSubNameDiv = styled.div`
  display: flex;
  max-width: 80%;
  gap: 8px;
`;
const StBlockDateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 65px;
`;
const StButtonDeleteBtn = styled.button<{ generateDate: boolean }>`
  height: 25px;
  width: 25px;
  ${({ generateDate }) =>
    generateDate &&
    `
    align-self: flex-start;
`}
`;

const StDateWrapDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6.5px;
`;
const StLineDateDiv = styled.div<{ isDeadline: boolean }>`
  display: flex;
  align-items: center;
  height: 24px;
  ${({ isDeadline }) =>
    isDeadline &&
    `
    background-color: ${Colors.LIGHT.red};
    border-radius: 3px;
    padding-right: 6px;
  `}
`;
const StBlockProjectDiv = styled.div<{ isCompleted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-radius: 6px;
  height: 24px;
  overflow: hidden;
  background-color: ${({ isCompleted }) => (isCompleted ? Colors.LIGHT.disabled : Colors.LIGHT.background.blue100)};
`;
const StTextProjectTFont = styled(TextFont)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
`;
