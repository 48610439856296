import { useMemo } from "react";

import { IMeet, IMeetParticipants, IUserMeetPermissions } from "@interfaces/meet.interface";
import { userSelector } from "@store/user";

export interface IUseRoleModel {
  isRoleModelEnabled: boolean;
  isUserHasEditPermissions: boolean;
  isUserHasInvitePermissions: boolean;
}

export const useRoleModel = (meet: IMeet | null | undefined): IUseRoleModel => {
  const { currentUser, directorId } = userSelector();

  const checkIsRoleModelEnabled = (participants: IMeetParticipants[]) => {
    for (const user of participants) {
      if (user.permissions?.length) return true;
    }

    return false;
  };

  const getUserMeetingPermissions = (userId: string, participants: IMeetParticipants[]): IUserMeetPermissions => {
    const result = {
      canEditMeeting: false,
      canAddParticipants: false,
    };

    for (const user of participants) {
      if (user?.user?.id === userId) {
        if (user.permissions?.includes("ADD_PARTICIPANTS")) {
          result.canAddParticipants = true;
        }

        if (user.permissions?.includes("EDIT_MEETING")) {
          result.canEditMeeting = true;
        }
      }
    }

    return result;
  };
  const userIdToCheck = directorId || (currentUser?.id ?? "");
  const isRoleModelEnabled = meet ? checkIsRoleModelEnabled(meet.participants ?? []) : false;
  const userMeetPermissions = getUserMeetingPermissions(userIdToCheck, meet?.participants ?? []);

  const isUserHasEditPermissions = Boolean(meet?.createdByCurrentUser) || (isRoleModelEnabled && userMeetPermissions.canEditMeeting);
  const isUserHasInvitePermissions = Boolean(meet?.createdByCurrentUser) || (isRoleModelEnabled && userMeetPermissions.canAddParticipants);

  return useMemo(
    () => ({
      isRoleModelEnabled,
      isUserHasEditPermissions,
      isUserHasInvitePermissions,
    }),
    [isUserHasEditPermissions, isUserHasInvitePermissions, isRoleModelEnabled],
  );
};
