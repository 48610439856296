import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import dayjs from "dayjs";

import { IMeet } from "@interfaces/meet.interface";
import { IGetUserParams, IParticipationListResponse, TOutlookAuthStatus, IZoomInfo, TZoomAuthStatus } from "@interfaces/user.interface";
import { toFormatDate } from "@utils/toFormatTime";

import { StoreTagTypes, api } from "./api";
import { IExternalUser, IGetUsersByEmailListDTO } from "./meet.service";
import { IParamsDelegationList } from "./user.service";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getParticipationList: build.query<IParticipationListResponse, IGetUserParams>({
      query: ({ id, params }) => {
        if (!params) return "";

        const meetId = id ? `/${id}` : "";

        return {
          url: `/api/api-gateway/v1/meetings${meetId}/participants?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 60,
      providesTags: [StoreTagTypes.Users],
    }),
    getExternalParticipantsList: build.query<IExternalUser[], { searchQuery: string }>({
      query: ({ searchQuery }) => ({
        url: `/api/calendar/v1/external-users?query=${searchQuery}`,
        method: "GET",
      }),
      keepUnusedDataFor: 60,
      providesTags: [StoreTagTypes.ExternalUser],
    }),
    createExternalUsersByEmailList: build.mutation<IExternalUser[], { data: { emails: string[] }; userId?: string }>({
      query: ({ data, userId }) => {
        const assistandQueryParam = userId ? `?userId=${userId}` : "";

        return {
          url: `/api/calendar/v1/external-users/by-email${assistandQueryParam}`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: () => [StoreTagTypes.ExternalUser, StoreTagTypes.MixedUsers],
    }),
    editExternalParticipant: build.mutation<
      IExternalUser,
      { externalUserId: string; data: { firstName: string; lastName: string }; userId?: string }
    >({
      query: ({ externalUserId, userId, data }) => {
        const assistandQueryParam = userId ? `?userId=${userId}` : "";

        return {
          url: `/api/calendar/v1/external-users/${externalUserId}${assistandQueryParam}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: () => [StoreTagTypes.ExternalUser, StoreTagTypes.MixedUsers],
    }),
    getUserListByEmails: build.query<IGetUsersByEmailListDTO, { emails: string[] }>({
      query: ({ emails }) => ({
        url: "/api/api-gateway/v1/users/all/by-email?withNotActive=false",
        method: "POST",
        data: emails,
      }),
      keepUnusedDataFor: 0,
      providesTags: [StoreTagTypes.MixedUsers],
    }),
    getPastMeetsList: build.query<IMeet[], { searchQuery: string }>({
      query: ({ searchQuery }) => {
        const [startDate, endDate] = [toFormatDate(dayjs().subtract(1, "month")), toFormatDate(dayjs())];
        return {
          url: `/api/calendar/v1/meetings/period?name=${searchQuery}&startDate=${startDate}&endDate=${endDate}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 60,
      providesTags: [StoreTagTypes.PastMeets],
    }),
    getUsersAvailableToDelegationList: build.query<IParticipationListResponse, { id: string; params: IParamsDelegationList }>({
      keepUnusedDataFor: 60,
      query: ({ id, params }) => {
        const taskId = id ? `/${id}` : "";
        const adjustedUrl = params
          ? `/api/api-gateway/v1/business-tasks${taskId}/delegation?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : `/api/api-gateway/v1/business-tasks${taskId}/delegation?`;

        return {
          url: adjustedUrl,
          method: "GET",
        };
      },
      transformResponse: (response: IParticipationListResponse, _, arg) => {
        if (arg?.id && "availableUsers" in response) {
          return response?.availableUsers as IParticipationListResponse;
        }
        return response;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: StoreTagTypes.Users as const, id })),
              { type: StoreTagTypes.Users, id: "PARTIAL-LIST" },
            ]
          : [{ type: StoreTagTypes.Users, id: "PARTIAL-LIST" }],
    }),
    authOutlook: build.mutation<{ status: TOutlookAuthStatus }, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: "/api/outlook-ews-integration/v1/authorize",
        data: { username, password },
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let patchOutlookAuthStatus: PatchCollection | undefined = undefined;

        try {
          const { data: response } = await queryFulfilled;
          patchOutlookAuthStatus = dispatch(api.util.updateQueryData("getOutlookAuthStatus", undefined, () => response));
        } catch (error) {
          patchOutlookAuthStatus?.undo();
        }
      },
    }),
    revokeAuthOutlook: build.mutation({
      query: () => ({
        url: "/api/outlook-ews-integration/v1/unauthorize",
        method: "DELETE",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let patchOutlookAuthStatus: PatchCollection | undefined = undefined;

        try {
          const { data: response } = await queryFulfilled;
          patchOutlookAuthStatus = dispatch(api.util.updateQueryData("getOutlookAuthStatus", undefined, () => response));
        } catch (error) {
          patchOutlookAuthStatus?.undo();
          console.error(error);
        }
      },
    }),
    getOutlookAuthStatus: build.query<{ status: TOutlookAuthStatus }, void>({
      query: () => ({
        url: "/api/outlook-ews-integration/v1/authorize/status",
        method: "GET",
      }),
      keepUnusedDataFor: 60,
    }),
    getZoomInfo: build.query<IZoomInfo, undefined>({
      query: () => ({
        url: "/calendar/v1/video-conferences/user-info/self",
        method: "GET",
      }),
      keepUnusedDataFor: 60,
      providesTags: [{ type: StoreTagTypes.Users, id: "ZOOM-INFO" }],
    }),

    revokeAuthZoom: build.mutation({
      query: () => ({
        url: "/calendar/v1/zoom/auth/token/revoke",
        method: "POST",
      }),
      // eslint-disable-next-line no-empty-pattern
      async onQueryStarted({}, { dispatch }) {
        try {
          dispatch(api.util.updateQueryData("getZoomInfo", undefined, () => null));
          dispatch(api.util.updateQueryData("getZoomAuthStatus", undefined, () => "NOT_AUTHORIZED"));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getZoomAuthStatus: build.query<TZoomAuthStatus, undefined>({
      query: () => ({
        url: "/calendar/v1/zoom/auth/token/status",
        method: "GET",
      }),
      transformResponse: (response: { status: TZoomAuthStatus; refreshTokenExpiresAt: string | null }) => response.status,
      keepUnusedDataFor: 60,
    }),
  }),
});

export const {
  useGetParticipationListQuery,
  useGetExternalParticipantsListQuery,
  useGetUsersAvailableToDelegationListQuery,
  useGetPastMeetsListQuery,
  useAuthOutlookMutation,
  useGetOutlookAuthStatusQuery,
  useRevokeAuthOutlookMutation,
  useGetZoomInfoQuery,
  useRevokeAuthZoomMutation,
  useGetZoomAuthStatusQuery,
  useEditExternalParticipantMutation,
  useCreateExternalUsersByEmailListMutation,
  useGetUserListByEmailsQuery,
} = userApi;
