import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { HEIGHT_BLOCK, WIDTH_BLOCK } from "../constants";

interface IRow {
  hour: number;
  date: Dayjs;
  rows: number;
  isLightColumnNumber: number;
  time: { startTime: string; endTime: string };
}

export const Row: FC<IRow> = ({ hour, date, rows, isLightColumnNumber, time }) => {
  const currentMinute = dayjs().minute();

  const isCurrentHour = useMemo(() => hour === dayjs().hour() && date.isSame(dayjs(), "date"), [date, hour]);

  const dynamicPadding = useMemo(() => currentMinute * (WIDTH_BLOCK / 60), [currentMinute]);

  const isHourActive = useMemo(() => {
    if (!dayjs(time.startTime).isSame(dayjs(date), "day")) return false;

    const startTime = dayjs(time?.startTime);
    const endTime = dayjs(time?.endTime);
    return (startTime.hour() === hour && startTime.minute() === 0) || (endTime.hour() === hour && endTime.minute() === 0);
  }, [time, hour]);

  const formatHour = useMemo(() => (hour < 10 ? `0${hour}` : hour), [hour]);

  return (
    <StContainerDiv>
      <div style={{ pointerEvents: "none" }}>
        <StHourText size={14} isHourActive={isHourActive}>
          {formatHour}
        </StHourText>
      </div>

      <StColumnWrapDiv>
        {[...Array(rows).keys()].map((i) => (
          <StColumnItemDiv isActive={isLightColumnNumber === i} key={i}>
            <StLineDottedDiv />
          </StColumnItemDiv>
        ))}
      </StColumnWrapDiv>

      <RenderWithCondition condition={isCurrentHour}>
        <StBlockTimeDiv paddingLeft={dynamicPadding}>
          <StCircleTimeDiv />
          <StLineTimeDiv />
        </StBlockTimeDiv>
      </RenderWithCondition>
    </StContainerDiv>
  );
};

const StContainerDiv = styled.div`
  position: relative;
  width: ${WIDTH_BLOCK}px;
`;

const StCircleTimeDiv = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: ${Colors.LIGHT.red};
`;

const StLineTimeDiv = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Colors.LIGHT.red};
`;

const StBlockTimeDiv = styled.div<{ paddingLeft: number }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  z-index: 3;
  height: 300%;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
`;

const StHourText = styled(TextFont)<{ isHourActive: boolean }>`
  display: flex;
  align-items: end;
  margin-left: -5px;
  padding-bottom: 3px;
  width: ${WIDTH_BLOCK}px;
  height: 31px;
  top: 0;
  color: ${(props) => (props.isHourActive ? Colors.LIGHT.text.accent : Colors.LIGHT.text.grey400)} !important;
  ${({ isHourActive }) => isHourActive && "font-weight: 700"};
`;

const StColumnWrapDiv = styled.div`
  position: relative;
  border-top: 1px solid ${Colors.LIGHT.lighGrey};
`;

const StColumnItemDiv = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  height: ${HEIGHT_BLOCK}px;
  width: ${WIDTH_BLOCK}px;
  border-right: 1px solid ${Colors.LIGHT.lighGrey};
  border-top: 1px solid ${Colors.LIGHT.lighGrey};
  ${({ isActive }) =>
    isActive &&
    `background-color: ${Colors.LIGHT.lightGrey300};
   opacity: 0.5`};
`;

const StLineDottedDiv = styled.div`
  height: 100%;
  width: 1.5px;

  background-image: linear-gradient(${Colors.LIGHT.lighGrey} 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1.5px 10px;
  background-repeat: repeat-y;
`;
