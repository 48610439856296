import axios from "axios";

import { http } from "@api/httpClient";
import { REFRESH_TOKEN } from "@constants/settings";
import { logOutUtil } from "@utils/appUtils";
import { secureStore } from "@utils/secureStore";

export type DataGetAuthToken = {
  phone_number: string;
  sms_code: string;
  grant_type: "sms_code";
  device: {
    device_id: string;
    brand: string;
    model: string;
    app_version: string;
    operating_system: string;
  };
};

export type ResponseGetAuthToken = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
};

export type ResponseGetAuthSmsCode = {
  secondsToRetry: number;
  registered: boolean;
};

export interface DataRegister {
  firstName: string;
  lastName: string;
  middleName: string;
}

const getAxiosEntity = async (basicToken: string) =>
  axios.create({
    baseURL: `https://${window.location.host}`,
    withCredentials: true,
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  });

class AuthService {
  private standardToken = process.env.REACT_APP_BASE_BASIC_STANDARD_TOKEN;
  private standardTokenMobile = process.env.REACT_APP_BASE_BASIC_STANDARD_MOBILE_TOKEN;
  private adminToken = process.env.REACT_APP_BASE_BASIC_ADMIN_TOKEN;

  getAuthSmsCode = async (phoneNumber: string): Promise<ResponseGetAuthSmsCode> => {
    const http = await getAxiosEntity(this.adminToken);
    return await http.post(`/api/api-gateway/v1/confirmation/sms-code/${phoneNumber}`);
  };

  register = async (data: DataRegister): Promise<any> => await http.patch("/api/uaa/v1/users/me", data);

  getAuthToken = async (data: DataGetAuthToken): Promise<ResponseGetAuthToken> => {
    const http = await getAxiosEntity(this.standardTokenMobile);
    return await http.post("/api/uaa/oauth/token", data);
  };

  updateTokensApi = async () => {
    try {
      const refresh_token = await secureStore.getValue(REFRESH_TOKEN);
      const body = { refresh_token, grant_type: REFRESH_TOKEN };
      const http = await getAxiosEntity(this.standardToken);

      return await http.post("/api/uaa/oauth/token", body);
    } catch (error: any) {
      if (error?.request?.status === 401) {
        logOutUtil();
        window.location.pathname = "/auth";
      }
    }
  };
}

export const authService = new AuthService();
