export class DelayTimer {
  private currentDelay: number;
  private readonly initialDelay: number = 500;
  private readonly maxDelay: number = 600000; // 10 mins in ms

  constructor() {
    this.currentDelay = this.initialDelay;
  }

  getNextDelay(): number {
    const delay = this.currentDelay;
    this.currentDelay = Math.min(this.currentDelay + 10000, this.maxDelay);

    return delay;
  }

  reset(): void {
    this.currentDelay = this.initialDelay;
  }
}
