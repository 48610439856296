import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState, useRef } from "react";

import { union, unionBy } from "lodash";
import { useSelector } from "react-redux";

import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser, TSubListInfo } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";
import { useGetExternalParticipantsListQuery, useGetParticipationListQuery, useGetUserListByEmailsQuery } from "@services/userApi";
import { getSublistParams } from "@services/utils";
import { selectUserForAssistant } from "@store/user";
import EventHelperUtils from "@utils/event-helper.utills";
import { isEmail } from "@utils/isEmail";
import { showToast } from "@utils/toast";

const defaultInputMaxLength = 255;
const extendedInputMaxLength = 15000;
const next = 16;
const eventHelperUtils = new EventHelperUtils();

interface IHookProps {
  meet?: IMeet;
  isGroupAll?: boolean;
  searchQuery?: string;
  customInitialParticipants?: (IStateUser | IExternalUser)[];
  disableInnerUsers?: boolean;
  disableExternalUsers?: boolean;
  showOnlySearched?: boolean;
  isSelectAllChecked?: boolean;
  ignoreSelectedUsers?: boolean;
  setIsSelectAllChecked?: Dispatch<React.SetStateAction<boolean>>;
}

interface IHookReturn {
  listData: (string | IStateUser | IExternalUser)[];
  selectedUsers: (IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[];
  selectedEmails: string[];
  isInviteExternalParticipantBtnVisible: boolean;
  isInviteExternalParticipantModalVisible: boolean;
  isLoading: boolean;
  isFetching: boolean;
  search: string;
  searchedExternalUsersResponse: IExternalUser[];
  initialParticipants: (IStateUser | IExternalUser)[];
  getUserQueryParams: IGetUserParams;
  isMultipleEmails: boolean;
  isCheckBtnHidden: boolean;
  isSelectAllChecked: boolean;
  isSelectAllBtnVisible: boolean;
  trimmedSearch: string;
  inputMaxLength: number;
  isCheckingForMultipleEvents: boolean;
  setCurrent: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
  handleResetSearch: () => void;
  loadMoreData: () => Promise<void>;
  setSearchedExternalUsers: Dispatch<SetStateAction<IExternalUser[]>>;
  setGetUsersParams: Dispatch<SetStateAction<IGetUserParams>>;
  setIsInviteExternalParticipantModalVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedUsers: Dispatch<SetStateAction<(IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[]>>;
  setSelectedEmails: Dispatch<SetStateAction<string[]>>;
  setCurrentSublistType: Dispatch<SetStateAction<TSubListInfo>>;
  handleEmailsSearch: () => void;
  injectCreatedExternalParticipant: (user: IExternalUser) => void;
  setIsSelectAllChecked: Dispatch<SetStateAction<boolean>>;
  handleEmailPress: (email: string) => void;
  manualSetIsSelectAllChecked: (value: boolean) => void;
  setSkipSearchByEmail: Dispatch<SetStateAction<boolean>>;
  setIsManualSearchByEmailsLock: Dispatch<SetStateAction<boolean>>;
  handleOnPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  setAllExternalUsers: React.Dispatch<React.SetStateAction<IExternalUser[]>>;
}

export const useHandleParticipantSearch = ({
  meet,
  isGroupAll,
  showOnlySearched,
  disableInnerUsers = false,
  disableExternalUsers = false,
  ignoreSelectedUsers = false,
}: IHookProps): IHookReturn => {
  const [isInviteExternalParticipantModalVisible, setIsInviteExternalParticipantModalVisible] = useState(false);
  const [isInviteExternalParticipantBtnVisible, setIsInviteExternalParticipantBtnVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [userToSearch, setUserToSearch] = useState("");
  const [current, setCurrent] = useState(0);
  const [participantsUsers, setParticipantsUsers] = useState({ content: [] as IStateUser[], total: 0 });
  const [searchedExternalUsers, setSearchedExternalUsers] = useState<IExternalUser[]>([]);
  const [emailsToCreateUsers, setEmailsToCreateUsers] = useState<string[]>([]);
  const [currentSublistType, setCurrentSublistType] = useState<TSubListInfo>("Delegation");
  const [isMultipleEmails, setIsMultipleEvents] = useState(false);
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const [skipSearchByEmail, setSkipSearchByEmail] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isCheckBtnHidden, setIsCheckBtnHidden] = useState(false);
  const [isSelectAllBtnVisible, setIsSelectAllBtnVisible] = useState(false);
  const [listDataOnlyUsers, setListDataOnlyUsers] = useState<(IExternalUser | IStateUser)[]>([]);
  const [listDataOnlyEmails, setListDataOnlyEmails] = useState<string[]>([]);
  const [isManualSearchByEmailsLock, setIsManualSearchByEmailsLock] = useState(false);
  const [allInnerUsers, setAllInnerUsers] = useState<IStateUser[]>([]);
  const [allExternalUsers, setAllExternalUsers] = useState<IExternalUser[]>([]);
  const [inputMaxLength, setInputMaxLength] = useState(defaultInputMaxLength);
  const [isCheckingForMultipleEvents, setIsCheckingForMultipleEvents] = useState(false);

  const trimmedSearch = useMemo(() => search.trim(), [search]);

  const allFilteredInnerUsers = useRef<IStateUser[]>([]);
  const allFilteredExternalUsers = useRef<IExternalUser[]>([]);
  const isWriting = useRef(false);

  const recalculateOnSelectAllChange = useRef(false);

  const currentUser = useSelector(selectUserForAssistant);

  const defaultQueryParams = useMemo(
    () => ({ next, startTime: meet?.startTime ?? null, endTime: meet.endTime ?? null }),
    [meet.endTime, meet?.startTime],
  );

  const defaultGetUserParams = useMemo(
    () => ({
      id: meet?.id ?? "",
      params: {
        startTime: meet?.startTime ?? null,
        endTime: meet?.endTime ?? null,
        current: 0,
        next,
        showFromDelegationChain: true,
        showFromDepartment: false,
        showOthers: false,
        active: true,
      },
    }),
    [meet?.endTime, meet?.id, meet?.startTime],
  );

  const [getUsersParams, setGetUsersParams] = useState<IGetUserParams>(defaultGetUserParams);

  const {
    data: searchedExternalUsersResponse,
    isLoading: isLoadingExternal,
    isFetching: isFetchingExternal,
  } = useGetExternalParticipantsListQuery(
    {
      searchQuery: userToSearch,
    },
    {
      skip: disableExternalUsers || isMultipleEmails,
    },
  );

  const {
    data: participantsUsersResponse,
    isLoading: isLoadingInner,
    isFetching: isFetchingInner,
  } = useGetParticipationListQuery(
    {
      ...getUsersParams,
      params: { ...getUsersParams.params },
    },
    {
      skip: disableInnerUsers || isMultipleEmails,
    },
  );

  const {
    data: searchByEmailsResponse,
    isLoading: isSearchByEmailsLoading,
    isFetching: isSearchByEmailFetching,
    isSuccess: isSearchByEmailsSuccess,
  } = useGetUserListByEmailsQuery({ emails: validEmails }, { skip: skipSearchByEmail });

  const isLoading = useMemo(
    () => isLoadingExternal || isLoadingInner || isSearchByEmailsLoading,
    [isLoadingExternal, isLoadingInner, isSearchByEmailsLoading],
  );
  const isFetching = useMemo(
    () => isFetchingInner || isFetchingExternal || isSearchByEmailFetching,
    [isFetchingInner, isFetchingExternal, isSearchByEmailFetching],
  );

  const initialParticipants: (IStateUser | IExternalUser)[] = useMemo(() => {
    const participants = [];

    if (meet?.participants) {
      for (const user of meet.participants) {
        Object.hasOwn(user, "user") ? participants.push(user.user) : participants.push(user);
      }
    }

    if (meet?.externalUsers?.length) {
      const externalUserWithoutDeleted = meet?.externalUsers.map((ex) => ({ ...ex, isExternalUser: true })).filter((ex) => !ex.deleted);
      participants.push(...externalUserWithoutDeleted);
    }
    return participants;
  }, [meet]);

  const [selectedUsers, setSelectedUsers] = useState<(IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem[]>>(initialParticipants);

  const getUserQueryParams: IGetUserParams = useMemo(() => {
    const searchQuery = trimmedSearch ? { query: trimmedSearch } : {};
    const additionalSearchQueryParams = trimmedSearch
      ? {}
      : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false, active: true };

    return {
      id: meet?.id ?? "",
      params: {
        ...searchQuery,
        current: 0,
        next,
        startTime: meet?.startTime ?? "",
        endTime: meet?.endTime ?? "",
        ...additionalSearchQueryParams,
      },
    } as IGetUserParams;
  }, [meet, trimmedSearch]);

  useEffect(() => {
    if (trimmedSearch && isWriting.current) return;

    setAllInnerUsers(unionBy(allInnerUsers, participantsUsersResponse?.content ?? [], "id"));
  }, [participantsUsersResponse?.content, isMultipleEmails]);

  useEffect(() => {
    if (trimmedSearch && isWriting.current) return;

    const mergedLists = [...allExternalUsers, ...(searchedExternalUsersResponse ?? [])].reduce((acc, item) => {
      acc.set(item.email, item);
      return acc;
    }, new Map());

    setAllExternalUsers(Array.from(mergedLists.values()));
  }, [searchedExternalUsersResponse, isMultipleEmails]);

  const listData = useMemo(() => {
    const selectedUsersIds = selectedUsers.map((item) => item.id);
    const filteredInner = allInnerUsers.filter((item) => !selectedUsersIds.includes(item.id));
    const filteredExternal = allExternalUsers
      ?.filter((item) => !selectedUsersIds.includes(item?.id) && !selectedUsersIds.includes(item?.email))
      .map((i) => ({ ...i, isExternalUser: true }));

    const filteredSelected = selectedUsers?.filter((user) => user?.id !== currentUser?.id);

    allFilteredInnerUsers.current = filteredInner;
    allFilteredExternalUsers.current = filteredExternal;

    if (trimmedSearch && isMultipleEmails) {
      const filteredInnerUsers = participantsUsers?.content?.filter((item) => !selectedUsersIds.includes(item.id));
      const filteredExternalUsers = searchedExternalUsers
        ?.filter((item) => !selectedUsersIds.includes(item?.id) && !selectedUsersIds.includes(item?.email))
        .map((i) => ({ ...i, isExternalUser: true }));

      const externalAndInner = [...(participantsUsers?.content ?? []), ...searchedExternalUsers];
      const filteredSelected = selectedUsers.filter((su) => externalAndInner.some((eu) => eu.id === su.id));

      return [...filteredExternalUsers, ...filteredInnerUsers, ...filteredSelected, ...emailsToCreateUsers];
    }

    const parsedSelected = [];

    for (const item of filteredSelected) {
      if ("user" in item && typeof item?.user === "object") {
        parsedSelected.push({ ...item?.user });
      } else {
        parsedSelected.push(item);
      }
    }

    if (trimmedSearch && showOnlySearched) {
      const innerToShow = (participantsUsers?.content ?? [])?.filter((pu) => selectedUsers.some((su) => su.id === pu.id)) ?? [];
      const externalToShow = searchedExternalUsersResponse?.filter((se) => selectedUsers.some((su) => su.email === se.email)) ?? [];

      return [...allFilteredExternalUsers.current, ...allFilteredInnerUsers.current, ...externalToShow, ...innerToShow];
    }

    if (trimmedSearch) {
      return isGroupAll
        ? [...allFilteredExternalUsers.current, ...parsedSelected]
        : [...allFilteredExternalUsers.current, ...allFilteredInnerUsers.current, ...parsedSelected, ...emailsToCreateUsers];
    }

    return isGroupAll
      ? [...parsedSelected, ...allFilteredExternalUsers.current]
      : [...parsedSelected, ...allFilteredExternalUsers.current, ...allFilteredInnerUsers.current];
  }, [
    trimmedSearch,
    isMultipleEmails,
    selectedUsers,
    searchedExternalUsers,
    participantsUsers?.content,
    currentUser,
    emailsToCreateUsers,
    allInnerUsers,
    allExternalUsers,
  ]);

  const manualSetIsSelectAllChecked = useCallback(
    (value: boolean) => {
      setIsSelectAllChecked(value);
      recalculateOnSelectAllChange.current = !recalculateOnSelectAllChange.current;
    },
    [recalculateOnSelectAllChange.current],
  );

  const injectCreatedExternalParticipant = useCallback(
    (user: IExternalUser) => {
      setSearchedExternalUsers([...searchedExternalUsers, user]);
      setEmailsToCreateUsers([...emailsToCreateUsers.filter((email) => email !== user.email)]);
      setSelectedUsers([...selectedUsers, user] as unknown as (IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem[]>);
    },
    [emailsToCreateUsers, searchedExternalUsers, selectedUsers],
  );

  const loadMoreData = useCallback(async () => {
    if (participantsUsers && (currentSublistType === "Others" || trimmedSearch) && current >= participantsUsers.total) return;

    let sublistUpdation = currentSublistType;

    if (current > participantsUsers.total && !trimmedSearch) {
      sublistUpdation = currentSublistType === "Delegation" ? "Department" : "Others";
    }

    const sublistParams = trimmedSearch ? {} : getSublistParams(sublistUpdation);

    const params = {
      id: meet.id,
      params: { query: trimmedSearch, current, ...sublistParams, ...defaultQueryParams },
    };

    setGetUsersParams(params);
    setCurrent(current + next);
    !trimmedSearch && setCurrentSublistType(sublistUpdation);
  }, [participantsUsers, current, trimmedSearch]);

  const [invalidEmails, setInvalidEmails] = useState([]);

  const handleEmailsSearch = useCallback(() => {
    if (invalidEmails && invalidEmails.length) {
      const toastText = `E-mail ${invalidEmails.join(", ")} не соответствуют формату`;
      showToast(toastText);
    }

    setSearchedExternalUsers([]);
    setEmailsToCreateUsers([]);
    setSkipSearchByEmail(false);
  }, [invalidEmails]);

  const checkIsMultipleEmails = (text: string) => {
    const explodedString = text.split(/[\s,;\n]+/);
    const emails: string[] = [];
    const nonEmailEntries: string[] = [];

    for (const item of explodedString) {
      if (isEmail(item)) {
        emails.push(item);
      } else {
        Boolean(item) && nonEmailEntries.push(item);
      }
    }

    const isSeveralEmails = emails.length >= 2;

    setSkipSearchByEmail(true);
    setValidEmails(emails);
    setInvalidEmails(nonEmailEntries);
    setInputMaxLength(isSeveralEmails ? extendedInputMaxLength : defaultInputMaxLength);
    setIsMultipleEvents(isSeveralEmails);
    setIsCheckingForMultipleEvents(false);

    return isSeveralEmails;
  };

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const pastedText = (event.clipboardData.getData("Text") ?? "").trim();
      const isSeveralEmails = checkIsMultipleEmails(pastedText);

      if (isSeveralEmails) {
        setSearch(pastedText.replace(/\n/g, " "));
        return;
      }

      setSearch(pastedText.slice(0, 256));
    },
    [setSearch],
  );

  const handleEmailPress = useCallback(
    (email: string) => {
      if (selectedEmails.includes(email)) {
        setSelectedEmails(selectedEmails.filter((item) => item !== email));
      } else {
        setSelectedEmails([...selectedEmails, email].sort());
      }
    },
    [selectedEmails],
  );

  const handleResetSearch = useCallback(() => {
    allFilteredInnerUsers.current = [];
    allFilteredExternalUsers.current = [];

    setIsCheckBtnHidden(true);
    setIsSelectAllBtnVisible(false);
    setSkipSearchByEmail(true);
    setValidEmails([]);
    setInvalidEmails([]);
    setSearchedExternalUsers([]);
    setEmailsToCreateUsers([]);
    setParticipantsUsers({ content: [], total: 0 });
    setGetUsersParams({
      id: meet.id,
      params: { ...defaultQueryParams, showFromDelegationChain: true, current: 0 },
    });
  }, [meet]);

  /* Handle search by multiple emails */
  useEffect(() => {
    if (!searchByEmailsResponse || skipSearchByEmail || isManualSearchByEmailsLock) return;

    const { users, externalUsers, externalEmailsToCreate } = searchByEmailsResponse;

    if (users) setParticipantsUsers({ content: users, total: users.length });
    if (externalUsers) setSearchedExternalUsers(externalUsers);
    if (externalEmailsToCreate) setEmailsToCreateUsers(externalEmailsToCreate);

    const updatedSelectedUsers = [];

    for (const user of users ?? []) {
      if (selectedUsers.some((u) => u.id === user.id)) {
        updatedSelectedUsers.push(user);
      }
    }

    for (const extUser of externalUsers ?? []) {
      if (selectedUsers.some((u) => u.id === extUser.id || u.email === extUser.email)) {
        updatedSelectedUsers.push(extUser);
      }
    }

    setSelectedUsers(updatedSelectedUsers);
  }, [searchByEmailsResponse, isSearchByEmailFetching, isSearchByEmailsLoading]);

  useEffect(() => {
    if (trimmedSearch) {
      isWriting.current = true;
    }

    setIsCheckingForMultipleEvents(true);

    eventHelperUtils.debounce(() => {
      isWriting.current = false;
      checkIsMultipleEmails(trimmedSearch);
    }, 1000);

    if (trimmedSearch && !isMultipleEmails) {
      setAllInnerUsers([]);
      setAllExternalUsers([]);
    }

    if (!trimmedSearch) {
      setValidEmails([]);
      setInvalidEmails([]);
      setSkipSearchByEmail(true);
    }

    setSearchedExternalUsers([]);
    setEmailsToCreateUsers([]);
    setParticipantsUsers({ content: [], total: 0 });

    allFilteredInnerUsers.current = [];
    allFilteredExternalUsers.current = [];

    setIsCheckBtnHidden(false);
    setIsSelectAllBtnVisible(false);
    setGetUsersParams(defaultGetUserParams);

    const timeoutId = setTimeout(
      () => {
        setUserToSearch(trimmedSearch);
      },
      trimmedSearch ? 1000 : 0,
    );

    return () => clearTimeout(timeoutId);
  }, [trimmedSearch]);

  useEffect(() => {
    if (isSearchByEmailsSuccess) {
      setIsCheckBtnHidden(true);
      setIsSelectAllBtnVisible(true);
    }
  }, [isSearchByEmailsSuccess]);

  useEffect(() => {
    if (!isMultipleEmails && !listData.length) {
      setIsSelectAllBtnVisible(false);
    }
  }, [isMultipleEmails, listData]);

  useEffect(() => {
    if (!searchedExternalUsers?.length && !participantsUsersResponse?.content?.length && isEmail(trimmedSearch) && !isLoading) {
      setIsInviteExternalParticipantBtnVisible(true);
    } else {
      setIsInviteExternalParticipantBtnVisible(false);
    }
  }, [trimmedSearch, searchedExternalUsers, participantsUsersResponse, isLoading]);

  useEffect(() => {
    const mergedArrs = unionBy(showOnlySearched ? [] : participantsUsers.content, participantsUsersResponse?.content ?? [], "id");

    const update = {
      content: mergedArrs,
      total: participantsUsersResponse?.total ?? 0,
    };

    setParticipantsUsers(update);
  }, [participantsUsersResponse, showOnlySearched]);

  useEffect(() => {
    setSearchedExternalUsers(searchedExternalUsersResponse ?? []);
  }, [searchedExternalUsersResponse]);

  useEffect(() => {
    const users: (IStateUser | IExternalUser)[] = [];
    const emails: string[] = [];

    listData.map((item) => {
      if (typeof item === "string") {
        emails.push(item);
      } else {
        users.push(item);
      }
    });

    setListDataOnlyUsers(users);
    setListDataOnlyEmails(emails.sort());
  }, [listData]);

  useEffect(() => {
    if (isMultipleEmails && !isSelectAllChecked) {
      setSelectedUsers(initialParticipants ?? []);
      setSelectedEmails([]);

      return;
    }

    const mergedArray = [...selectedUsers, ...listDataOnlyUsers].filter(
      (item, index, array) =>
        array.findIndex((obj) => obj.id === item.id || (obj as unknown as IMeetParticipants)?.userId === item.id) === index,
    ) as (IExternalUser | IStateUser)[];

    setSelectedUsers(mergedArray);
    setSelectedEmails(union(selectedEmails, listDataOnlyEmails));
  }, [recalculateOnSelectAllChange.current]);

  /* Check current selectAll checkbox value */
  useEffect(() => {
    if (!isMultipleEmails || !(emailsToCreateUsers?.length || searchedExternalUsers.length || participantsUsers.content.length)) return;

    const allParticipants = ignoreSelectedUsers ? [...(meet?.participants ?? []), ...(meet?.externalUsers ?? [])] : selectedUsers;

    if ((allParticipants.length === 1 && !(meet?.externalUsers ?? []).length && !selectedEmails.length) || !isCheckBtnHidden) return;

    const containsAllEmails = listDataOnlyEmails.every((se) => selectedEmails.includes(se));
    const containsAllUsers = listDataOnlyUsers.every((ldu) =>
      allParticipants.some((ap) => (ap as IStateUser)?.email === ldu.email || (ap as IMeetParticipants)?.user?.email === ldu.email),
    );

    const isContainsAllUsersAndEmails = containsAllUsers && containsAllEmails;

    if (!isSelectAllChecked && isContainsAllUsersAndEmails) {
      setIsSelectAllChecked(true);
    }

    if (isSelectAllChecked && !isContainsAllUsersAndEmails) {
      setIsSelectAllChecked(false);
    }
  }, [meet, ignoreSelectedUsers, selectedEmails, listData, listDataOnlyUsers, listDataOnlyEmails]);

  return useMemo(
    () => ({
      search,
      listData,
      selectedUsers,
      selectedEmails,
      isLoading,
      isFetching,
      isInviteExternalParticipantBtnVisible,
      isInviteExternalParticipantModalVisible,
      searchedExternalUsersResponse,
      initialParticipants,
      getUserQueryParams,
      isMultipleEmails,
      isCheckBtnHidden,
      isSelectAllChecked,
      isSelectAllBtnVisible,
      trimmedSearch,
      inputMaxLength,
      isCheckingForMultipleEvents,
      setSearch,
      setCurrent,
      handleResetSearch,
      loadMoreData,
      setSearchedExternalUsers,
      setGetUsersParams,
      setIsInviteExternalParticipantModalVisible,
      setSelectedUsers,
      setSelectedEmails,
      setCurrentSublistType,
      handleEmailsSearch,
      injectCreatedExternalParticipant,
      setIsSelectAllChecked,
      handleEmailPress,
      manualSetIsSelectAllChecked,
      setSkipSearchByEmail,
      setIsManualSearchByEmailsLock,
      handleOnPaste,
      setAllExternalUsers,
    }),
    [
      search,
      listData,
      selectedUsers,
      selectedEmails,
      isLoading,
      isFetching,
      isInviteExternalParticipantBtnVisible,
      isInviteExternalParticipantModalVisible,
      searchedExternalUsersResponse,
      initialParticipants,
      getUserQueryParams,
      isMultipleEmails,
      isCheckBtnHidden,
      isSelectAllChecked,
      isSelectAllBtnVisible,
      trimmedSearch,
      inputMaxLength,
      isCheckingForMultipleEvents,
      setSearch,
      setCurrent,
      handleResetSearch,
      loadMoreData,
      setSearchedExternalUsers,
      setGetUsersParams,
      setIsInviteExternalParticipantModalVisible,
      setSelectedUsers,
      setSelectedEmails,
      setCurrentSublistType,
      handleEmailsSearch,
      injectCreatedExternalParticipant,
      setIsSelectAllChecked,
      handleEmailPress,
      manualSetIsSelectAllChecked,
      setSkipSearchByEmail,
      setIsManualSearchByEmailsLock,
      handleOnPaste,
      setAllExternalUsers,
    ],
  );
};
