import React, { ChangeEvent, FC } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@components/hoc";
import { selectUserForAssistant } from "@store/user";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { NotificationPeriod, NotificationTypes } from "../../../../../../interfaces/userSettings.interface";

interface IRadioFormReports {
  style?: React.CSSProperties;
  options: Record<string, string>[];
  startChecked: string;
  selectedValue: string;
  handleChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioFormReports: FC<IRadioFormReports> = ({ style, options, startChecked, selectedValue, handleChange }) => {
  const currentUser = useSelector(selectUserForAssistant);

  return (
    <StRadioWrapForm style={{ ...style }}>
      {options.map((item, index: number) => (
        <StLineRadioDiv key={item.label + index}>
          <StRadioInput
            type="radio"
            defaultChecked={item.id === startChecked}
            value={item.id}
            name="period"
            id={item.id}
            onChange={handleChange}
            disabled={item.label === "48" || item.label === "72"}
          />
          <StRadioLabel className="radio-label" htmlFor={item.id}>
            <TextFont
              size={16}
              weight="700"
              lineHeight={20}
              color={item.label === "48" || item.label === "72" ? Colors.LIGHT.placeholder.main : Colors.LIGHT.text.main}
            >
              {index === 0 && selectedValue === NotificationPeriod.MINUTES ? "" : item.label}
              <RenderWithCondition condition={index === 0 && selectedValue === NotificationPeriod.MINUTES}>
                <FormattedMessage id="atStartOfMeeting" />
              </RenderWithCondition>
              <RenderWithCondition condition={item.id === NotificationTypes.EMAIL}>
                <>{` (${currentUser?.email})`}</>
              </RenderWithCondition>
            </TextFont>
          </StRadioLabel>
        </StLineRadioDiv>
      ))}
    </StRadioWrapForm>
  );
};

export const StRadioWrapForm = styled.form`
  ${boxCenterCss}
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;
export const StLineRadioDiv = styled.div`
  ${boxCenterCss}
  justify-content: flex-start;
  gap: 1px;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  width: 100%;
`;
export const StRadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  &:checked + .radio-label:before {
    content: "";
    background-color: ${Colors.LIGHT.text.accent};
    transition: all 0.3s;
  }
  &:disabled + .radio-label:before {
    background-color: ${Colors.LIGHT.placeholder.main};
  }
  &:disabled + .radio-label:after {
    border: 2px solid ${Colors.LIGHT.placeholder.main};
  }
`;
export const StRadioLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-size: 18px;
  line-height: 20px;
  white-space: nowrap;
  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 17px;
    position: absolute;
    left: 4px;
    bottom: 4px;
    background-color: ${Colors.LIGHT.white};
    border-radius: 50%;
    transition: all 0.5s;
    z-index: 1;
    box-sizing: border-box;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 0px;
    background-color: ${Colors.LIGHT.white};
    border: 2px solid ${Colors.LIGHT.text.accent};
    border-radius: 50%;
    transition: all 0.5s;
    box-sizing: border-box;
  }
`;
