import { useState, useEffect, useMemo } from "react";

import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { IMeet, IMeetRepeat } from "@interfaces/meet.interface";
import { selectCurrentDate } from "@store/screenDay";
import { toFormatDate } from "@utils/toFormatTime";

import { tempMeetRepeat } from "../../constants";

type TArgs = { meet: IMeet };

export const useData = ({ meet }: TArgs) => {
  const [data, setData] = useState<IMeetRepeat>({
    ...(meet.repeat ?? tempMeetRepeat),
    type: meet.repeat?.type ?? "WEEK",
    endTime: meet.repeat?.endTime ? toFormatDate(dayjs(meet.repeat?.endTime)) : null,
  });
  const [isVisibleType, setIsVisibleType] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dateNow = useSelector(selectCurrentDate);

  useEffect(() => {
    setIsEdit(
      !isEqual(data, {
        ...meet.repeat,
        type: meet.repeat?.type ?? "WEEK",
        endTime: meet.repeat?.endTime ? toFormatDate(dayjs(meet.repeat?.endTime)) : null,
      }) &&
        (data.type === "WEEK" ? !!data.dayOfWeeks?.length : !!data?.days?.length) &&
        !isNotValidPeriod,
    );
  }, [data]);

  useEffect(() => {
    if (data.type !== "WEEK") {
      handleData("dayOfWeeks", null);
    }
  }, [data.type]);

  const handleData = (name: keyof IMeetRepeat, value: any) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const isNotValidPeriod = useMemo(() => isNaN(data.repetitionPeriod) || Number(data.repetitionPeriod) < 1, [data.repetitionPeriod]);

  const isRenderResult = useMemo((): boolean => {
    if (data.type === "WEEK") {
      return Boolean(data.dayOfWeeks?.length);
    }
    return Boolean(data?.days?.length);
  }, [data.type, data?.days?.length, data.dayOfWeeks?.length]);

  return useMemo(
    () => ({
      data,
      isVisibleType,
      setIsVisibleType,
      isCancel,
      setIsCancel,
      isEdit,
      dateNow,
      handleData,
      isRenderResult,
      setData,
      isNotValidPeriod,
    }),
    [data, isVisibleType, isNotValidPeriod, isCancel, isEdit, dateNow, isRenderResult],
  );
};
