import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";

import { TextFont } from "../TextFont";

import { NotificationUI } from "./NotificationUI";

interface IModalSuccess {
  phrase?: string;
}

const defaultPhrase = "Цель удалена";

export const NotificationSuccess: FC<IModalSuccess> = ({ phrase = defaultPhrase }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsVisible(false), 2000);
  }, []);

  return (
    <NotificationUI isVisible={isVisible} style={{ ...styles.container, backdropFilter: "blur(3.5px)" }}>
      <div style={styles.modal}>
        <TextFont style={styles.text}>
          <FormattedMessage id={phrase} />
        </TextFont>
      </div>
    </NotificationUI>
  );
};

const styles = {
  container: {
    height: "2px",
    padding: "10px",
    justifyContent: "flex-end",
    zIndex: 999,
    marginBottom: "75px",
    marginLeft: "13px",
    marginRight: "13px",
    width: "30%",
  },
  modal: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    padding: "10px",
    borderRadius: "7px",
  },
  text: {
    "text-align": "left",
    fontSize: "16px",
    color: "#ffffff",
  },
};
