import { useRef, useEffect, useState, useCallback, useMemo, ChangeEvent } from "react";

import { useDispatch } from "react-redux";

import { IFile } from "@interfaces/files.interfaces";
import { uploadFile } from "@store/fileAttachments/api";

import { downloadFile } from "./api";
import { getUniqueMerge, removeFilesFromServer } from "./utils";

export enum FileUploadStatuses {
  Uploading = "Uploading",
  UploadSuccess = "UploadSuccess",
  UploadFailed = "UploadFailed",
  Idle = "Idle",
}

export interface IUploadedFile {
  id: string;
  name: string;
}

export interface IRemoveFilesFromServer {
  removeUploaded?: boolean;
  removeMarkedToDelete?: boolean;
}

interface IUseFileAttachments {
  selectedFiles: File[];
  fileUploadStatus: FileUploadStatuses;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  fileIdsToRemove: string[];
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  resetUploadedFiles: () => void;
  handleFileChange: (files: FileList) => void;
  FileInputField: () => JSX.Element;
  resetFileIdsToRemove: () => void;
  openFilePicker: (params?: { closeOverModal?: () => void }) => void;
  removeUploadedFilesFromServer: (params?: IRemoveFilesFromServer) => void;
  restoreSoftDeletedFiles: () => void;
}

interface IProps {
  initialFiles: IFile[];
}

export const useFileAttachments = ({ initialFiles }: IProps): IUseFileAttachments => {
  const [pickedFile, setPickedFile] = useState<File[]>([]);
  const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatuses>(FileUploadStatuses.Idle);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [fileIdsToRemove, setFileIdsToRemove] = useState<string[]>([]);
  const [allFiles, setAllFiles] = useState<IFile[]>([]);
  const restoredInitialFiles = useRef<IFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const uploadSelectedFile = async (formData: FormData) => await dispatch(uploadFile(formData));

  const fileUploadHandler = useCallback(
    async (isRetry?: boolean) => {
      if (!selectedFiles.length) {
        return;
      }

      setFileUploadStatus(FileUploadStatuses.Uploading);

      const formData = new FormData();

      for await (const file of selectedFiles) {
        formData.append("file", file);
      }

      const response = await uploadSelectedFile(formData);

      if (response.payload === undefined && !isRetry) {
        setTimeout(() => {
          fileUploadHandler(true);
        }, 500);
        return;
      }

      if (response.payload === undefined && isRetry) {
        setUploadedFiles([]);
        setSelectedFiles([]);
        return;
      }

      const data = response?.payload?.data;
      const newlyUploadedFile: IFile = {
        fileName: data?.fileName,
        id: data?.id,
        fileId: data?.fileId,
        bucket: data?.bucket,
        sourceUrl: data?.sourceUrl ?? null,
      };
      const mergedLists = getUniqueMerge(allFiles, uploadedFiles, [], [newlyUploadedFile]);

      setFileUploadStatus(FileUploadStatuses.UploadSuccess);
      setUploadedFiles([...uploadedFiles, newlyUploadedFile]);
      setAllFiles(mergedLists);
      setSelectedFiles([]);
    },
    [allFiles, uploadedFiles, selectedFiles],
  );

  const deleteFile = useCallback(
    (id: string) => {
      const fileteredAllFiles = allFiles.filter((file) => file.id !== id);
      const filteredUploadedFiles = uploadedFiles.filter((file) => file.id !== id);

      setFileIdsToRemove([...fileIdsToRemove, id]);
      setAllFiles(fileteredAllFiles);
      setUploadedFiles(filteredUploadedFiles);
    },
    [allFiles, uploadedFiles, fileIdsToRemove],
  );

  const handleFileDownload = useCallback((id: string, fileName: string) => dispatch(downloadFile({ id, fileName })), []);

  const handleFileChange = useCallback(
    async (files: FileList) => {
      const currentSelectedFiles: File[] = [];

      for (const file of files) {
        const sf = file as File;
        currentSelectedFiles.push(sf);
      }

      setPickedFile(currentSelectedFiles);
    },
    [pickedFile, fileInputRef],
  );

  const resetUploadedFiles = useCallback(() => setUploadedFiles([]), []);
  const resetFileIdsToRemove = useCallback(() => setFileIdsToRemove([]), []);

  const restoreSoftDeletedFiles = useCallback(() => {
    setAllFiles(restoredInitialFiles.current);
  }, [restoredInitialFiles]);

  const openFilePicker = useCallback(() => {
    fileInputRef?.current?.click();
  }, [fileInputRef.current]);

  const FileInputField = useCallback(
    () => <input type="file" ref={fileInputRef} onChange={(e) => handleFileChange(e.target.files)} style={{ display: "none" }} />,
    [handleFileChange, fileInputRef],
  );

  const removeUploadedFilesFromServer = useCallback(
    (params?: IRemoveFilesFromServer) => {
      const isRemoveUploaded = params?.removeUploaded ?? false;
      const isRemoveMarkedToDelete = params?.removeMarkedToDelete ?? true;

      const removeUploaded = () => {
        if (uploadedFiles.length) {
          removeFilesFromServer(uploadedFiles.map((file) => file.id));
          setUploadedFiles([]);
        }
      };

      const removeMarkedToDelete = () => {
        if (fileIdsToRemove.length) {
          removeFilesFromServer(fileIdsToRemove.map((id) => id));
          setFileIdsToRemove([]);
        }
      };

      if (isRemoveUploaded) removeUploaded();
      if (isRemoveMarkedToDelete) removeMarkedToDelete();

      resetUploadedFiles();
    },
    [uploadedFiles, fileIdsToRemove],
  );

  useEffect(() => {
    initialFiles && setAllFiles(initialFiles);
    restoredInitialFiles.current = initialFiles ?? [];
  }, [initialFiles]);

  useEffect(() => {
    const uniqueFiles = pickedFile.reduce((acc: File[], obj) => {
      if (!selectedFiles.some((item) => item.name === obj.name)) {
        acc.push(obj);
      }

      return acc;
    }, []);

    setSelectedFiles(uniqueFiles);
  }, [pickedFile]);

  useEffect(() => {
    fileUploadHandler();
  }, [selectedFiles]);

  return useMemo(
    () => ({
      selectedFiles,
      uploadedFiles,
      allFiles,
      fileUploadStatus,
      fileIdsToRemove,
      deleteFile,
      handleFileDownload,
      resetUploadedFiles,
      handleFileChange,
      openFilePicker,
      FileInputField,
      resetFileIdsToRemove,
      removeUploadedFilesFromServer,
      restoreSoftDeletedFiles,
    }),
    [
      allFiles,
      selectedFiles,
      uploadedFiles,
      fileIdsToRemove,
      fileUploadStatus,
      deleteFile,
      FileInputField,
      handleFileDownload,
      resetFileIdsToRemove,
      removeUploadedFilesFromServer,
      restoreSoftDeletedFiles,
      handleFileChange,
      openFilePicker,
      resetUploadedFiles,
    ],
  );
};
