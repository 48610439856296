import React, { FC, useCallback, useEffect, useState } from "react";

import FlatList from "flatlist-react";
import styled from "styled-components";

import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { ModalUI, HeaderModal, SearchInput, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { showToast } from "@utils/toast";

import { Item } from "../../participants/participantsList/Item";

interface IProps {
  onClose: () => void;
  meet: IEventsOfDay;
  onPress: (arg: string) => void;
}

export const ToManage: FC<IProps> = ({ onClose, meet, onPress }) => {
  const [search, setSearch] = useState("");
  const [selectId, setSelectId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (meet.repeat || meet.parentEvent) {
      showToast("changeAuthorForAllInSeries");
    }
  }, []);

  const matchedParticipants = (meet?.participants ?? []).filter((i) => {
    const isEqual = new RegExp(search).test(
      `${(i as IMeetParticipants).user?.firstName} ${(i as IMeetParticipants).user?.middleName} ${
        (i as IMeetParticipants).user?.lastName
      } ${(i as IMeetParticipants).user?.email}`,
    );
    return isEqual && i.status !== "OWNER";
  });

  const renderItem = useCallback(
    ({ item }: { item: IMeetParticipants }) => (
      <Item
        key={item.id}
        isBusy
        isAdded={false}
        item={{ ...item.user }}
        onPress={(arg) => setSelectId(arg.id)}
        isRenderCheckbox={false}
        isDeletable={false}
      />
    ),
    [],
  );

  return (
    <ModalUI isVisible onClose={onClose} scrollEnable={false}>
      <HeaderModal title="chooseAuthor" leftSide={{ onPressClose: onClose }} />

      <Container>
        <SearchInput value={search} setValue={setSearch} isDrop multipleEmailsSearch={false} />
        <ListWrap>
          <FlatList
            renderWhenEmpty={() => <TextNotFound align="center">Не найдено</TextNotFound>}
            list={matchedParticipants.map((i) => ({ item: i }))}
            keyExtractor={(i) => i.id}
            renderItem={renderItem}
          />
        </ListWrap>
      </Container>

      {selectId && (
        <NotificationConfirm
          phraseId="chooseAuthorQuestion"
          phraseOkId="yes"
          onOk={() => onPress(selectId)}
          onCancel={() => setSelectId(undefined)}
        />
      )}
    </ModalUI>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 0 12px;
  gap: 20px;
  height: 100%;
`;
const ListWrap = styled.div`
  overflow-y: auto;
  height: 85%;
`;
const TextNotFound = styled(TextFont)`
  text-align: center;
  padding-top: 10px;
`;
