import { InterfaceBusinessTask } from "@interfaces";

export const defaultFilter: InterfaceBusinessTask.IFilter = {
  name: "",
  projectId: null,
  projectGroupId: null,
  tagId: null,
  groupByType: "NONE",
  createdDateStartRange: null,
  createdDateEndRange: null,
  planedEndDateStartRange: null,
  planedEndDateEndRange: null,
  priority: null,
  executorType: null,
  delegatedFrom: null,
  delegatedFromType: null,
  delegatedFromUsers: null,
  delegatedTo: null,
  delegatedToType: null,
  delegatedToUsers: null,
  status: null,
  pageable: {
    current: 0,
    next: 25,
    total: 0,
  },
  workDayDate: null,
};

export enum TaskSubtypes {
  Business = "BUSINESS",
  Personal = "PERSONAL",
}
