import { NotificationDurations, NotificationTypes } from "../../../../../../interfaces/userSettings.interface";

export const mapMinutes = [
  { id: NotificationDurations.NOW, label: "0", value: NotificationDurations.NOW },
  { id: NotificationDurations.ONE_MINUTE, label: "1", value: NotificationDurations.ONE_MINUTE },
  { id: NotificationDurations.FIVE_MINUTES, label: "5", value: NotificationDurations.FIVE_MINUTES },
  { id: NotificationDurations.TEN_MINUTES, label: "10", value: NotificationDurations.TEN_MINUTES },
  { id: NotificationDurations.FIFTEEN_MINUTES, label: "15", value: NotificationDurations.FIFTEEN_MINUTES },
  { id: NotificationDurations.THIRTY_MINUTES, label: "30", value: NotificationDurations.THIRTY_MINUTES },
];
export const mapHours = [
  { id: NotificationDurations.ONE_HOUR, label: "1", value: NotificationDurations.ONE_HOUR },
  { id: NotificationDurations.TWELVE_HOURS, label: "12", value: NotificationDurations.TWELVE_HOURS },
  { id: NotificationDurations.ONE_DAY, label: "24", value: NotificationDurations.ONE_DAY },
];
export const mapTypes = [
  { id: NotificationTypes.PUSH, label: "Уведомление", value: NotificationTypes.PUSH },
  { id: NotificationTypes.EMAIL, label: "Письмо на почту", value: NotificationTypes.EMAIL },
];
