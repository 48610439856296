import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ACCESS_TOKEN, PHONE_NUMBER } from "@constants/settings";
import { StoreTagTypes, api } from "@services/api";
import { authService, DataGetAuthToken, DataRegister } from "@services/auth.service";
import { getError } from "@store/getError";
import { secureStore, showToast } from "@utils";

export const getCodeApi: any = createAsyncThunk("auth/getCode", async (phoneNumber: string, thunkAPI) => {
  try {
    const response = await authService.getAuthSmsCode(phoneNumber);

    return { response, phoneNumber };
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return thunkAPI.rejectWithValue("404");
    }
    if (error?.response?.status === 502 || error?.response?.status === 504) {
      showToast("connectServerAlert", "error");
    }
    return getError(error, thunkAPI);
  }
});

export const loginApi: any = createAsyncThunk("auth/login", async (data: DataGetAuthToken, thunkAPI) => {
  try {
    const response = await authService.getAuthToken(data);
    const phoneNumberUser = localStorage.getItem(PHONE_NUMBER);

    if (phoneNumberUser !== data.phone_number) {
      thunkAPI.dispatch(api.util.invalidateTags(Object.values(StoreTagTypes)));
      secureStore.saveValue(PHONE_NUMBER, data.phone_number);
    }

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI, error.response.data?.error_description);
  }
});

export const checkAuth: any = createAsyncThunk("auth/checkAuth", async (thunkAPI) => {
  try {
    const accessToken = secureStore.getValue(ACCESS_TOKEN);
    return { accessToken };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const registerApi = createAsyncThunk("auth/registerApi", async (data: DataRegister, thunkAPI) => {
  try {
    const response = await authService.register(data);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
