import React, { FC, useState } from "react";

import { ITask } from "@interfaces/businessGoals.interface";
import { IStateUser } from "@interfaces/user.interface";
import { DelegatedList } from "@shared";
import { HeaderModal, ModalUI } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";

interface IProps {
  task: ITask;
  onClose: () => void;
  onSave: (arg: IStateUser) => void;
}

export const SelectAuthor: FC<IProps> = ({ task, onClose, onSave }) => {
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const [selectUser, setSelectUser] = useState<IStateUser | null>(null);

  const handleOnSave = (arg: IStateUser) => {
    setIsVisibleConfirm(true);
    setSelectUser(arg);
  };
  return (
    <ModalUI isVisible={true} onClose={onClose}>
      <HeaderModal title="selectUser" leftSide={{ onPressClose: onClose, isHideCancel: true }} />

      <DelegatedList task={task} close={onClose} onSave={handleOnSave} isModal={true} isNeedCloseAfterSave={false} />

      {isVisibleConfirm && selectUser && (
        <NotificationConfirm
          phraseId="chooseTaskAuthorQuestion"
          phraseOkId="yes"
          onOk={() => onSave(selectUser)}
          onCancel={() => setIsVisibleConfirm(false)}
        />
      )}
    </ModalUI>
  );
};
