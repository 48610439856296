export const ACCESS_TOKEN = "access_token";
export const BASE_URL = "url";
export const REFRESH_TOKEN = "refresh_token";
export const SETTINGS = "settings";
export const THEME = "theme";
export const DEVICE_ID = "device_id";
export const EXTRA_THEME = "extra_theme";
export const DIRECTOR_ID = "director_id";
export const DIRECTOR_LINK = "director_link";
export const PHONE_NUMBER = "phone_number";

export const URL_DEV = "https://app.dt.dev.tap2v.com";
export const URL_STAGE = "https://app.dt.test.tap2v.com";
export const URL_PROD = "https://app.dogmatime.ru/";

export const RECAPTCHA_SITE_KEY = "6Lemz94pAAAAAADod6V6AiTFVV7zO7xiGY_yvmn_";

export const SCREEN_PADDING = {
  paddingLeft: 12,
  paddingRight: 12,
};

export const linkPrivacyPolicy = "https://tap2visit.ru/privacy-policy/";
export const linkTermsOfUse = "https://tap2visit.ru/terms-of-use/";
export const linkAppStore = "https://apps.apple.com/ru/app/dogma-time/id6446031620";
export const linkGooglePlay = "https://play.google.com/store/apps/details?id=com.tap2v.dogmatime";
