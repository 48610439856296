import React, { FC, useEffect, useRef, useState, useCallback, MouseEvent, useMemo } from "react";

import { isArray, unionBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgAddPeople } from "@assets/people_add.svg";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { SelectAllButton } from "@components/shared/userList/SelectAllButton";
import { NotificationConfirm } from "@components/ui/notification/NotificationConfirm";
import { RenderWithCondition, LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser } from "@interfaces/user.interface";
import { InviteExternalParticipantBottomSheet } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { AddNonExistingParticipantButton } from "@screens/day/components/timetable/main/modal/components/participants/components/AddNonExistingParticipantButton";
import { Groups, UserInformation } from "@screens/profile/components";
import { StoreTagTypes, api } from "@services/api";
import { IExternalUser } from "@services/meet.service";
import { useDeleteExternalParticipantMutation } from "@services/meetApi";
import { Colors } from "@theme/colors";
import { HeaderModal, TextFont, ModalUI, Loader, ArrowSvg, SearchInput, ItemListContentLoader } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { isExternalUser } from "@utils/userTypeChecks";

import { useHandleParticipantSearch } from "../../../hooks/useHandleParticipantSearch";
import { useRoleModel } from "../../accessRights/useRoleModel";
import { isArraysOfObjectsDiffer, TStringUnknownTupleArray } from "../../utils";

import { EmailItem } from "./EmailItem";
import { Item } from "./Item";
import { MeetsPastList } from "./MeetsPastList";
import RemoveParticipantsButton from "./RemoveParticipantsButton";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps {
  meet: IMeet;
  onSave: (
    user: (IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[],
    emailList?: string[],
    isExternalUser?: boolean,
  ) => Promise<void>;
  onClose: () => void;
  isSelectFromMeeting?: boolean;
  isSelectGroup?: boolean;
  isShowStatusBorder?: boolean;
  isMeet: boolean;
  handleData?: (name: string, value: unknown | TStringUnknownTupleArray) => void;
  setRefetchOnClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldResetMeet?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ParticipantsList: FC<IProps> = ({
  meet,
  onSave,
  onClose,
  handleData,
  setRefetchOnClose,
  setShouldResetMeet,
  isSelectFromMeeting = true,
  isSelectGroup = true,
  isShowStatusBorder = true,
  isMeet,
}) => {
  const isGroupAll = useMemo(() => meet?.presetGroups?.length && meet?.presetGroups[0].type === "ALL_IN_SPACE", [meet]);

  const {
    search,
    trimmedSearch,
    listData,
    isLoading,
    isFetching,
    selectedUsers,
    isInviteExternalParticipantBtnVisible,
    isInviteExternalParticipantModalVisible,
    searchedExternalUsersResponse,
    initialParticipants,
    selectedEmails,
    isSelectAllBtnVisible,
    isMultipleEmails,
    isCheckBtnHidden,
    isSelectAllChecked,
    inputMaxLength,
    isCheckingForMultipleEvents,
    setSearch,
    setCurrent,
    handleResetSearch,
    loadMoreData,
    setSearchedExternalUsers,
    setGetUsersParams,
    setIsInviteExternalParticipantModalVisible,
    setSelectedUsers,
    setCurrentSublistType,
    handleEmailsSearch,
    injectCreatedExternalParticipant,
    handleEmailPress,
    manualSetIsSelectAllChecked,
    setSkipSearchByEmail,
    setIsManualSearchByEmailsLock,
    handleOnPaste,
    setAllExternalUsers,
  } = useHandleParticipantSearch({ meet, isGroupAll });

  const dispatch = useDispatch();

  const listInnerRef = useRef<HTMLDivElement>(null);

  const [isWriting, setIsWriting] = useState(false);
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const [isListPastMeetsVisible, setIsListPastMeetsVisible] = useState(false);
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hotRefethOnClose, setHotRefetchOnClose] = useState(false);

  const [pos, setPos] = useState({ left: 0, top: 0 });
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [externalUserIdToDelete, setExternalUserIdToDelete] = useState("");
  const [deletedExternalUsersIds, setDeletedExternalUsersIds] = useState<string[]>([]);
  const [usersToRemove, setUsersToRemove] = useState<(IStateUser | IExternalUser | Partial<IUnitedGroupItem>)[]>([]);
  const [currentEditableUser, setCurrentEditableUser] = useState<IExternalUser>();
  const [emailToCreateUser, setEmailToCreateUser] = useState<string>(null);

  const { isUserHasEditPermissions, isUserHasInvitePermissions } = useRoleModel(meet);
  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const deletableUserIds = useMemo(() => new Set<string>(), []);

  const [deleteExternalParticipant] = useDeleteExternalParticipantMutation();

  const handleUserPress = useCallback(
    (data: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[] | Partial<IUnitedGroupItem>[]) => {
      listInnerRef.current && setScrollPosition(listInnerRef.current?.scrollTop);

      if (data[0]?.type === "ALL_IN_SPACE") {
        setSelectedUsers(data);
        onSave(data);
        onClose();
      }

      if (isArray(data)) {
        const tempUsers = data.filter((i) => !selectedUsers.some((su) => su.id === i.id)) as (InterfaceUser.IStateUser | IExternalUser)[];
        setSelectedUsers([...selectedUsers, ...tempUsers]);
      } else {
        const addedUser = selectedUsers.find((item) => {
          if (isExternalUser(item)) return item.id === data.id || item.email === data.email;

          return item.id === data.id;
        });

        if (addedUser) {
          deletableUserIds.delete(addedUser.id);

          setSelectedUsers(
            selectedUsers.filter((item) => {
              if (isExternalUser(item)) return item.email !== (addedUser as IExternalUser).email;

              return item.id !== addedUser.id;
            }),
          );
          setUsersToRemove([...usersToRemove, addedUser]);
        } else {
          deletableUserIds.add(data.id);
          const raw = [...selectedUsers];
          const orderOfUsers = isGroupAll && !meet?.id ? [raw[0], raw[1], data, ...raw.slice(2)] : [raw[0], data, ...raw.slice(1)];

          setSelectedUsers(raw.length ? orderOfUsers : [data]);
        }
      }
    },
    [setSelectedUsers, onSave, onClose, selectedUsers, deletableUserIds, usersToRemove, isGroupAll, meet?.id],
  );

  const handleSave = () => {
    if (isMultipleEmails && isSelectAllBtnVisible) {
      const selectedExternal: IExternalUser[] = [];
      const selectedInner: IMeetParticipants[] = [];

      selectedUsers.map((item) => {
        if (isExternalUser(item)) {
          selectedExternal.push(item);
        } else {
          selectedInner.push(item as unknown as IMeetParticipants);
        }
      });

      const filteredParticipants =
        (meet?.participants ?? []).filter(
          (participantItem) =>
            !usersToRemove.some((userToRemove) => participantItem.id === userToRemove.id || participantItem?.userId === userToRemove?.id),
        ) ?? [];

      const filteredExternal =
        (meet?.externalUsers ?? []).filter(
          (externalUser) =>
            !usersToRemove.some((userToRemove) => externalUser.id === userToRemove.id || externalUser?.email === userToRemove?.email),
        ) ?? [];

      const mergedInner = unionBy(filteredParticipants, selectedInner, "id");
      const mergedExternals = unionBy(filteredExternal, selectedExternal, "email");

      const patchedPayload = [...mergedInner, ...mergedExternals] as (IStateUser | IExternalUser)[];

      onSave(patchedPayload, selectedEmails);
      setUsersToRemove([]);
      onClose();

      return;
    }

    onSave(selectedUsers, selectedEmails);
    onClose();
  };

  useEffect(() => {
    setShouldResetMeet?.(Boolean(deletedExternalUsersIds.length));
  }, [deletedExternalUsersIds]);

  const isAdded = useCallback(
    (item: IStateUser | IExternalUser | string) => {
      if (typeof item === "string") {
        return selectedEmails.some((i) => i === item);
      }

      return selectedUsers.some((i) => {
        if (isExternalUser(i)) {
          return (i as IExternalUser).email === item.email;
        }
        return (i as unknown as IMeetParticipants)?.userId === item.id || i.id === item.id;
      });
    },
    [selectedUsers, selectedEmails],
  );

  const isUncheckable = useCallback(
    (item: IStateUser | IExternalUser | string) => {
      if (typeof item === "string") return true;

      if (isExternalUser(item)) {
        return !(meet?.externalUsers ?? []).some((extU) => extU.email === item.email);
      }

      return !(meet?.participants ?? []).some((user) => user.userId === item.id);
    },
    [meet],
  );

  useEffect(() => {
    if (listInnerRef.current && !trimmedSearch) {
      listInnerRef.current.scrollTop = scrollPosition;
    }
  }, [listData]);

  useEffect(() => {
    const handleCloseModal = () => {
      if (isOpenContextMenu) {
        setIsOpenContextMenu(false);
      }
    };

    document.addEventListener("click", handleCloseModal);

    return () => document.removeEventListener("click", handleCloseModal);
  }, [isOpenContextMenu]);

  const closeUserInfo = () => {
    setSelectUser(undefined);
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      setScrollPosition(scrollTop);

      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  useEffect(() => {
    setCurrentSublistType("Delegation");

    if (trimmedSearch) {
      setIsWriting(true);
    }

    eventHelperUtils.debounce(
      async () => {
        const query = trimmedSearch ? { query: trimmedSearch } : {};
        const additionalParams = trimmedSearch ? {} : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false };
        const params: IGetUserParams = {
          id: meet.id,
          params: {
            ...query,
            current: 0,
            next,
            startTime: meet?.startTime ?? null,
            endTime: meet?.endTime ?? null,
            active: true,
            ...additionalParams,
          },
        };

        if (!trimmedSearch) setGetUsersParams(params);

        if (!searchedExternalUsersResponse?.length) {
          setGetUsersParams(params);
          setCurrent(next);
        }
        setIsWriting(false);
      },
      trimmedSearch ? 1000 : 0,
    );
  }, [trimmedSearch, searchedExternalUsersResponse]);

  useEffect(() => {
    setIsSaveBtnVisible(isArraysOfObjectsDiffer(initialParticipants, selectedUsers));
  }, [selectedUsers, selectedEmails]);

  useEffect(
    () => () => {
      if (hotRefethOnClose) {
        dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
      }
    },
    [hotRefethOnClose],
  );

  const isLoaderVisible = useMemo(() => {
    if (isMultipleEmails) {
      return isFetching || isLoading;
    }

    return isFetching || isWriting || isLoading;
  }, [isFetching, isWriting, isLoading, isMultipleEmails]);

  const removeExternalParticipant = (externalUserId: string) => {
    deleteExternalParticipant({ externalUserId, meetId: meet.id, isMeet, disableUpdate: true }).then(() => {
      setSearchedExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setAllExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setSelectedUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setDeletedExternalUsersIds((prev) => [...prev, externalUserId]);
    });
  };

  const handleRightClick = async (e: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => {
    e.preventDefault();
    setExternalUserIdToDelete(item.id);
    setIsOpenContextMenu(true);
    setPos({ top: e.clientY, left: e.clientX });
  };
  const hightCalc = `calc(91% - ${isSelectGroup && !trimmedSearch ? "2%" : "0%"} - ${isSelectFromMeeting && !trimmedSearch ? "2%" : "0%"})`;

  const updateExternalParticipant = useCallback(
    (user: IExternalUser) => {
      const updatedParaticipants = meet?.externalUsers?.map((item) => {
        if (item.id === user.id) {
          return {
            ...item,
            firstName: user.firstName,
            lastName: user.lastName,
          };
        }

        return item;
      });

      if (setRefetchOnClose) {
        setRefetchOnClose?.(true);
      } else {
        setHotRefetchOnClose(true);
      }

      handleData?.("externalUsers", updatedParaticipants);
    },
    [handleData, meet],
  );

  return (
    <ModalUI scrollEnable={false} isVisible={true} onClose={onClose}>
      <HeaderModal
        title="participants"
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        rightSide={{ onPress: handleSave }}
        isEdit={isSaveBtnVisible}
      />
      <MainContainer>
        <SearchInput
          isDrop
          value={search}
          styleWrapper={filterContainer}
          setValue={setSearch}
          onDrop={handleResetSearch}
          placeholder="enterNameOrEmail"
          autoRenderDrop
          showCheckEmailsButton={isMultipleEmails}
          onCheckEmailsPress={handleEmailsSearch}
          isCheckBtnHidden={isCheckBtnHidden}
          maxLength={inputMaxLength}
          onPaste={handleOnPaste}
          isCheckingForMultipleEvents={isCheckingForMultipleEvents}
        />

        <RenderWithCondition condition={isInviteExternalParticipantBtnVisible}>
          <AddNonExistingParticipantButton
            email={search}
            setIsInviteExternalParticipantModalVisible={setIsInviteExternalParticipantModalVisible}
          />
        </RenderWithCondition>

        <RenderWithCondition condition={isMultipleEmails && listData?.length && isSelectAllBtnVisible}>
          <SelectAllButton handleCheck={manualSetIsSelectAllChecked} isChecked={isSelectAllChecked} />
        </RenderWithCondition>

        <RenderWithCondition condition={!isInviteExternalParticipantBtnVisible}>
          <>
            {!search && isSelectFromMeeting && !isGroupAll && (
              <SelectFromPastParticipantButton onClick={() => setIsListPastMeetsVisible(true)}>
                <AddParticipantWrapper>
                  <IconWrap>
                    <SvgAddPeople width={42} height={42} />
                  </IconWrap>
                  <TextFont size={16} weight="700" type="bold">
                    <FormattedMessage id="selectParticipantsFromMeetings" />
                  </TextFont>
                </AddParticipantWrapper>
                <ArrowSvg type="right" />
              </SelectFromPastParticipantButton>
            )}

            {!search && isSelectGroup && !isGroupAll && (
              <SelectFromPastParticipantButton onClick={() => setIsGroupVisible(true)}>
                <AddParticipantWrapper>
                  <SvgAddPeople fill={Colors.LIGHT.blue} width={42} height={42} />
                  <TextFont size={16} weight="700" type="bold">
                    <FormattedMessage id="selectGroup" />
                  </TextFont>
                </AddParticipantWrapper>
                <ArrowSvg type="right" />
              </SelectFromPastParticipantButton>
            )}

            <LoaderRenderWithCondition condition={isLoading} loaderComponent={<ItemListContentLoader width={"100%"} height={"100%"} />}>
              <ListItems ref={listInnerRef} hightCalc={hightCalc} onScroll={handleScroll}>
                <RenderWithCondition condition={!listData.length && !isFetching && !isLoading && !isWriting && !isMultipleEmails}>
                  <NotFoundText>Не найдено</NotFoundText>
                </RenderWithCondition>
                {listData.map((item) => (
                  <>
                    {typeof item === "string" ? (
                      <EmailItem
                        item={item}
                        onPress={handleEmailPress}
                        isAdded={isAdded(item)}
                        setIsCreationFormVisible={setIsInviteExternalParticipantModalVisible}
                        setEmailToCreateUser={setEmailToCreateUser}
                      />
                    ) : (
                      <>
                        <Item
                          isDeletable={deletableUserIds?.has(item.id)}
                          key={item.id}
                          isAdded={isAdded(item)}
                          isBusy={isShowStatusBorder}
                          item={item}
                          setSelectUser={setSelectUser}
                          onPress={handleUserPress}
                          onRightClick={handleRightClick}
                          isUserHasEditPermissions={isUserHasEditPermissions}
                          isUserHasInvitePermissions={isUserHasInvitePermissions}
                          meetOwnerId={(meet.participants && meet?.participants[0]?.userId) ?? ""}
                          meetId={meet?.id}
                          isUncheckable={isUncheckable(item)}
                          setCurrentEditableUser={setCurrentEditableUser}
                        />
                        {(item as IExternalUser).isExternalUser && !(item as IExternalUser).deleted && (
                          <RemoveParticipantsButton
                            externalUserId={item.id}
                            isVisible={isOpenContextMenu && item.id === externalUserIdToDelete}
                            position={{ left: pos.left, top: pos.top }}
                            onClick={() => {
                              setExternalUserIdToDelete(item.id);
                              setIsConfirmModalOpen(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                ))}

                <RenderWithCondition condition={isLoaderVisible}>
                  <Loader posY="95.5%" />
                </RenderWithCondition>
              </ListItems>
            </LoaderRenderWithCondition>
          </>
        </RenderWithCondition>

        <ModalRenderWithCondition condition={Boolean(selectUser)}>
          <ModalUI isVisible={Boolean(selectUser)} onClose={closeUserInfo}>
            <UserInformation user={selectUser} onClose={closeUserInfo} />
          </ModalUI>
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isInviteExternalParticipantModalVisible}>
          <InviteExternalParticipantBottomSheet
            participantEmail={emailToCreateUser ?? search}
            isVisible={isInviteExternalParticipantModalVisible}
            setIsVisible={setIsInviteExternalParticipantModalVisible}
            setSearch={setSearch}
            closeParticipant={onClose}
            injectCreatedExternalParticipant={injectCreatedExternalParticipant}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isListPastMeetsVisible}>
          <MeetsPastList onClose={() => setIsListPastMeetsVisible(false)} onPress={handleUserPress} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isGroupVisible}>
          <ModalUI isVisible={isGroupVisible} onClose={() => setIsGroupVisible(false)}>
            <Groups
              onClose={() => setIsGroupVisible(false)}
              forParticipants={true}
              onSave={handleUserPress}
              isGroupAll={isGroupAll}
              isExistMeetID={!!meet?.id}
            />
          </ModalUI>
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isConfirmModalOpen}>
          <NotificationConfirm
            phraseId="externalUserWillDelete"
            phraseOkId="delete"
            phraseCancelId="cancel"
            onOk={() => {
              removeExternalParticipant(externalUserIdToDelete);
              setIsConfirmModalOpen(false);
            }}
            onCancel={() => {
              setIsConfirmModalOpen(false);
            }}
          />
        </ModalRenderWithCondition>

        <RenderWithCondition
          condition={isInviteExternalParticipantModalVisible || Boolean(currentEditableUser) || Boolean(emailToCreateUser)}
        >
          <InviteExternalParticipantBottomSheet
            participantEmail={emailToCreateUser ?? search}
            isVisible={isInviteExternalParticipantModalVisible || Boolean(currentEditableUser)}
            setIsVisible={setIsInviteExternalParticipantModalVisible}
            closeParticipant={() => {
              setIsInviteExternalParticipantModalVisible(false);
              setCurrentEditableUser(undefined);
            }}
            setSearch={setSearch}
            injectCreatedExternalParticipant={injectCreatedExternalParticipant}
            user={currentEditableUser}
            isMultipleEmails={isMultipleEmails}
            updateExternalParticipant={updateExternalParticipant}
            setSkipSearchByEmail={setSkipSearchByEmail}
            setIsManualSearchByEmailsLock={setIsManualSearchByEmailsLock}
          />
        </RenderWithCondition>
      </MainContainer>
    </ModalUI>
  );
};

const NotFoundText = styled.p`
  text-align: center;
`;

const MainContainer = styled.div`
  padding: 0 12px;
  margin-top: 10px;
  height: 90%;
`;

const SelectFromPastParticipantButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.LIGHT.white};
`;

const AddParticipantWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const IconWrap = styled.div`
  width: 42px;
  height: 42px;
`;
const ListItems = styled.div<{ hightCalc: string }>`
  overflow-y: auto;
  height: ${({ hightCalc }) => hightCalc};
`;

const filterContainer = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
};
