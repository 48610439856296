import { IAttachEventMeta } from "@services/meet.service";

export const generateEventsMeta = (idsToAttach: string[], date: string) => {
  const eventsMeta: IAttachEventMeta[] = [];

  for (const taskId of idsToAttach) {
    eventsMeta.push({ date, id: taskId });
  }

  return eventsMeta;
};
