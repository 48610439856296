import React, { FC, useCallback, useState } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { ReactComponent as SvgDialog } from "@assets/dialog.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { THandleOpenWorkDay } from "../addWorkDaysReminder/useWorkDaysController";

import { Comment } from "./Comment";

export const bg = {
  COMPLETED: Colors.LIGHT.green100,
  NOT_COMPLETED: Colors.LIGHT.lightRed,
};

interface IProps {
  element: WorkDay;
  allowRemove?: boolean;
  press?: THandleOpenWorkDay;
  remove: (arg: string) => void;
  isFromOneS?: boolean;
}

export const RenderWorkDay: FC<IProps> = ({ element, press, remove, allowRemove = true, isFromOneS = false }) => {
  const [isVisibleComment, setIsVisibleComment] = useState<boolean>(false);

  const displayDate = useCallback((i: WorkDay) => `${dayjs(i.startTime).format("HH:mm")}-${dayjs(i.endTime).format("HH:mm")}`, []);

  const handlePressDay = (date: string) => {
    if (!press) return;

    press(date, !!element.startTime);
  };

  return (
    <ContentWrap backColor={element.status && bg[element.status]}>
      <ButtonItem disabled={!press} onClick={() => handlePressDay(element.date)}>
        <TextFont color={Colors.LIGHT.text.main}>
          {`${dayjs(element.date).format("DD MMMM")} ${element.startTime && element.endTime ? displayDate(element) : ""}`}
        </TextFont>
      </ButtonItem>

      {element.comment ? (
        <ButtonIcon onClick={() => setIsVisibleComment(true)}>
          <SvgDialog />
        </ButtonIcon>
      ) : (
        <>
          <RenderWithCondition condition={allowRemove && !isFromOneS}>
            <ButtonIcon onClick={() => remove(element.date)}>
              <SvgRemove fill={Colors.LIGHT.placeholder.main} width={9} height={9} />
            </ButtonIcon>
          </RenderWithCondition>
        </>
      )}

      <ModalRenderWithCondition condition={isVisibleComment}>
        <Comment date={element.date} comment={element.comment} close={() => setIsVisibleComment(false)} />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.section<{ backColor?: string }>`
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.LIGHT.white};
  padding: 5px;
  border-radius: 45px;
  ${({ backColor }) => backColor && `background-color: ${backColor}`};
`;
const ButtonItem = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  padding-right: 10px;
  height: 100%;
  padding-left: 7px;
`;
