import dayjs from "dayjs";

import { WorkDay } from "@interfaces/businessGoals.interface";

export const shouldShowWorkdayReminder = (workDays: WorkDay[]) => {
  const currentDate = dayjs();
  let shouldAddWorkDays = true;

  for (const item of workDays) {
    if (dayjs(item.date).isAfter(currentDate)) {
      shouldAddWorkDays = false;
      break;
    }
  }

  return shouldAddWorkDays;
};
