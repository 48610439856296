import React from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgDone } from "@assets/done.svg";
import { ReactComponent as SvgMove } from "@assets/move.svg";
import { ReactComponent as SvgReturnWork } from "@assets/return_work.svg";
import { ModalRenderWithCondition } from "@hoc";
import { IGoal, TypeStatusGoal } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui/TextFont";
import globalState from "@utils/globalState";

import { Modal } from "../../../header/modal/Modal";
import { useStartAction } from "../../useStartAction";

interface IActions {
  goal: IGoal;
  status: TypeStatusGoal;
  isEdit: boolean;
  setIsDelete: (arg: boolean) => void;
  close: () => void;
}

export const Actions = ({ goal, status, isEdit, setIsDelete, close }: IActions) => {
  const { colorSchema } = globalState;
  const { isVisibleMove, setIsVisibleMove, handleStatus, handleMove } = useStartAction(goal);

  const move = (yearMonth: Dayjs, isDisabled: boolean) => {
    const res = handleMove(yearMonth, isDisabled);
    if (res) {
      close();
    }
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  return (
    <section style={styles.container}>
      <TextFont type="bold" style={styles.mainTitle}>
        Доступные действия
      </TextFont>
      <div style={{ marginTop: 10 }}>
        {status === "NOT_DONE" ? (
          <button disabled={isEdit} onClick={() => handleStatus("DONE")} style={{ ...styles.button, ...styles.buttonDefault }}>
            <SvgDone fill={Colors.LIGHT.green} style={styles.image} />
            <TextFont color={colorSchema.text.accent} style={styles.text}>
              <FormattedMessage id="ready" />
            </TextFont>
          </button>
        ) : (
          <button disabled={isEdit} onClick={() => handleStatus("NOT_DONE")} style={{ ...styles.button, ...styles.buttonDefault }}>
            <SvgReturnWork style={styles.image} />
            <TextFont color={colorSchema.blue} style={styles.text}>
              <FormattedMessage id="returnToWork" />
            </TextFont>
          </button>
        )}
        {/* <button
          disabled={isEdit}
          onClick={() => setIsVisibleMove(true)}
          style={{ ...styles.button, ...styles.buttonDefault, marginTop: 5 }}
        >
          <SvgMove style={styles.image} fill="#FB9231" />
          <TextFont color={colorSchema.orange} style={styles.text}>
            <FormattedMessage id="transfer" />
          </TextFont>
        </button> */}

        <button disabled={isEdit} onClick={handleDelete} style={{ ...styles.button, ...styles.buttonRemove }}>
          <SvgDelete />
          <TextFont color={colorSchema.red} style={styles.text}>
            <FormattedMessage id="delete" />
          </TextFont>
        </button>
      </div>
      <ModalRenderWithCondition condition={isVisibleMove}>
        <Modal isVisible={isVisibleMove} setIsVisible={setIsVisibleMove} pressMonth={move} startDate={dayjs().format("YYYY-MM")} />
      </ModalRenderWithCondition>
    </section>
  );
};

const styles = {
  container: {
    marginTop: "20px",
  },
  button: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "8px",
    width: "100%",
  },
  buttonDefault: {
    borderRadius: "11px",
    backgroundColor: "#FFFFFF",
  },
  buttonRemove: {
    marginTop: "15px",
  },
  text: {
    fontSize: "18px",
    fontWeight: "400",
    marginLeft: "12px",
  },
  mainTitle: {
    fontSize: "18px",
    fontWeight: "700",
  },
  image: {
    width: "24px",
    height: "24px",
  },
};
