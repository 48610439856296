import React, { FC, useEffect, useCallback, useState } from "react";

import { NotificationUI } from "@ui/notification/NotificationUI";

interface INotificationCustom {
  children: React.ReactNode;
  onClose: () => void;
  hasBackdrop?: boolean;
  onCancel?: () => void;
  style?: any;
}

export const NotificationCustom: FC<INotificationCustom> = ({ children, onClose, ...props }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    onClose && onClose();
  }, [setIsVisible, onClose]);

  useEffect(() => {
    const handleOverlayClick = () => {
      const modalOverlay = document.getElementsByClassName("ReactModal__Overlay--after-open");

      if (!modalOverlay.length) return;

      onClose();
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, []);

  return (
    <NotificationUI isVisible={isVisible} style={styles.modalWrapper} hasBackdrop={false} onCancel={handleClose}>
      <div style={styles.modal}>{children}</div>
    </NotificationUI>
  );
};

const styles = {
  modalWrapper: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: "14px",
    // width: "35%",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "background-color": "rebeccapurple",
  },
};
