import React, { useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IGoal } from "@interfaces/businessGoals.interface";
import { deleteGoal } from "@store/businessGoals";
import { Notification } from "@ui/notification/Notification";
import { NotificationClear } from "@ui/notification/NotificationClear";
import { KeysButtons, IButton, TaskActions } from "@ui/taskActions";

import { Modal } from "../../header/modal/Modal";
import { useStartAction } from "../useStartAction";

interface IButtons {
  goal: IGoal;
  isVisible: boolean;
  pos: { left: number; top: number };
}

export const Buttons = ({ goal, isVisible, pos }: IButtons) => {
  const dispatch = useDispatch();

  const [isDelete, setIsDelete] = useState(false);
  const { isVisibleMove, setIsVisibleMove, handleMove, isVisibleContinue, setIsVisibleContinue, handleContinue, handleStatus } =
    useStartAction(goal);

  const pressStatus = () => {
    handleStatus(goal.status === "DONE" ? "NOT_DONE" : "DONE");
  };

  const pressMove = (type: "move" | "continue") => {
    if (type === "continue") {
      setIsVisibleContinue(true);
    } else {
      setIsVisibleMove(true);
    }
  };

  const pressDelete = () => {
    setIsDelete(true);
  };

  const handleDeleteNotification = useCallback(() => {
    dispatch(deleteGoal(goal.id));
  }, [goal.id]);

  const config = useMemo(
    (): Partial<Record<KeysButtons, IButton>> => ({
      [goal.status === "DONE" ? "returnToWork" : "readyGoal"]: { onClick: () => pressStatus() },
      transfer: { onClick: () => pressMove("move") },
      ...(goal.status === "NOT_DONE" ? { continueAgain: { onClick: () => pressMove("continue") } } : {}),
      delete: { onClick: () => pressDelete() },
    }),
    [goal.status],
  );

  const msgBusinessDeleteAlert = useIntl().formatMessage({ id: "businessDeleteAlert" });

  return (
    <>
      <RenderWithCondition condition={isVisible}>
        <TaskActions configuration={config} pos={pos} />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleMove}>
        <Modal
          isVisible={isVisibleMove}
          setIsVisible={setIsVisibleMove}
          pressMonth={handleMove}
          startDate={dayjs().format("YYYY-MM")}
          selectedMonths={goal.yearMonths}
          displayNoteSomeMonth={goal.yearMonths?.length > 1}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleContinue}>
        <Modal
          isVisible={isVisibleContinue}
          setIsVisible={setIsVisibleContinue}
          selectedMonths={goal.yearMonths ?? [goal.yearMonth]}
          pressMonth={handleContinue}
          startDate={dayjs().format("YYYY-MM")}
        />
      </ModalRenderWithCondition>
      <RenderWithCondition condition={isDelete}>
        {goal.businessTasks?.length ? (
          <NotificationClear phraseId="businessDeleteNotAllow" onOk={() => setIsDelete(false)} phraseOkId="Ок" />
        ) : (
          <Notification
            type="confirm"
            phraseId={msgBusinessDeleteAlert}
            onOk={handleDeleteNotification}
            onCancel={() => setIsDelete(false)}
          />
        )}
      </RenderWithCondition>
    </>
  );
};
