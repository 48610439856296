import React, { FC, useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { ReactComponent as SvgWrapperPeople } from "@assets/people_wrapper.svg";
import { LoaderRenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser, meetService } from "@services/meet.service";
import { useGetPastMeetsListQuery } from "@services/userApi";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, SearchInput, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { toFormatDateTimeRu } from "@utils/toFormatTime";

import { renderResult } from "../../viewNotSave/components/repeat/utils";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  onClose: () => void;
  onPress: (users: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[], isExternalUser?: boolean) => void;
}

export const MeetsPastList: FC<IProps> = ({ onClose, onPress }) => {
  const [search, setSearch] = useState("");
  const [meets, setMeets] = useState<IMeet[]>([]);
  const [params, setParams] = useState<{ searchQuery: string }>({ searchQuery: "" });

  const { data: response, isFetching } = useGetPastMeetsListQuery(params);

  useEffect(() => {
    eventHelperUtils.debounce(
      async () => {
        setParams({ searchQuery: search });
      },
      search ? 1000 : 0,
    );
  }, [response?.length, search]);

  useEffect(() => {
    setMeets(response ?? []);
  }, [response]);

  const handlePress = useCallback(async (meet: IMeet) => {
    onClose();

    const res = await meetService.getParticipantsAll(meet.id, {
      startTime: meet.startTime as string,
      endTime: meet.endTime as string,
    });

    const participants = [...res.data].filter((i) => !!i.user).map((i) => i.user);
    const tempUsersWithOutFired = [...participants].filter((user) => user.active);
    const tempUsersWithOutDeleted = [...meet.externalUsers].filter((user) => !user.deleted);
    onPress([...tempUsersWithOutFired, ...tempUsersWithOutDeleted]);
  }, []);

  return (
    <ModalUI isVisible scrollEnable={false} onClose={onClose}>
      <HeaderModal title="listOfMeetings" leftSide={{ onPressClose: onClose }} />

      <ContentWrap>
        <SearchWrap>
          <SearchInput value={search} setValue={setSearch} placeholder="Введите название встречи" isDrop multipleEmailsSearch={false} />
        </SearchWrap>

        <LoaderRenderWithCondition condition={isFetching}>
          <ListMeets>
            {meets.map((i) => (
              <ButtonItem key={i.id} onClick={() => handlePress(i)}>
                <SvgWrapperPeople />
                <ContentText>
                  <TextTitleMeet weight="700" size={16} type="bold">
                    {i.name}
                    {i.description ? ` - ${i.description}` : ""}
                  </TextTitleMeet>
                  <TextFont size={12} color={Colors.LIGHT.placeholder.main}>
                    {i.repeat
                      ? renderResult({
                          type: i.repeat.type,
                          endTime: i.repeat.endTime,
                          days: i.repeat.dayOfWeeks,
                          daysRepeat: i.repeat.days,
                          period: i.repeat.repetitionPeriod,
                        })
                      : toFormatDateTimeRu(dayjs(i.startTime))}
                  </TextFont>
                </ContentText>
              </ButtonItem>
            ))}
          </ListMeets>
        </LoaderRenderWithCondition>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  padding-top: 20px;
  height: 100%;
`;
const SearchWrap = styled.div`
  padding: 0 12px;
  margin-bottom: 20px;
`;
const ListMeets = styled.div`
  height: 83%;
  overflow-y: auto;
`;
const ContentText = styled.div`
  max-width: 80%;
`;
const ButtonItem = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 14px;
  padding: 8px 10px;
  background-color: ${Colors.LIGHT.white};
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const TextTitleMeet = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
