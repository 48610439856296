import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { ModalClose } from "@screens/day/components/ListTasks/components/modalClose";
import { PlanningWork } from "@shared/planningWork";
import { TaskSubtypes } from "@store/businessTask";
import { close, markWorkDay, takeMe, takeToWork } from "@store/businessTask/api";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserPermissoins } from "@store/user";
import { IButton, KeysButtons, TaskActions } from "@ui/taskActions";
import { statusOfDispatchEvent, toFormatDate } from "@utils";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";

import { ModalDelegate } from "../../components/modalDelegate/ModalDelegate";
import { tempWorkDay } from "../../Modal";
import { useWorkDaysController } from "../../Modal/components/addWorkDaysReminder";

interface IProps {
  item: ITask;
  isVisible: boolean;
  pos: { left: number; top: number };
}

export const ButtonsMoveTasks = ({ item, isVisible, pos }: IProps) => {
  const dispatch = useDispatch<any>();

  const [isDelegate, setIsDelegate] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [isAddDay, setIsAddDay] = useState(false);
  const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);
  const isPersonalTask = item.subType === TaskSubtypes.Personal;
  const isFromOneS = item.isFromOneS ?? false;

  const [WorkDaysModals] = useWorkDaysController({
    task: item,
    isPersonalTask,
    isAddWorkDayReminderVisible,
    setIsWorkDayReminderVisible,
  });

  const dateTrue = useSelector(selectCurrentDate);
  const permissions = useSelector(selectUserPermissoins);

  const handleTakeInWork = () => {
    setIsAddDay(true);
  };

  const handleSaveTakeInWork = async (days: WorkDay[]) => {
    const dateWeek = dayjs(days[0]?.date);
    const response = await dispatch(
      takeToWork({ id: item.id, date: toFormatDate(dateWeek), isAdd: dateWeek.isSame(dateTrue, "date"), isPersonalTask }),
    );

    if (statusOfDispatchEvent(response) === "fulfilled") {
      setIsAddDay(false);
    }
  };

  const handleClose = (reason: string, comment: string) => {
    dispatch(close({ amount: { id: item.id, reason, comment }, isPersonalTask }));
  };

  const handleDelegate = () => {
    setIsDelegate(true);
  };

  const dealWith = () => {
    dispatch(markWorkDay({ id: item.id, isPersonalTask }));
  };

  const handleTakeBack = () => {
    dispatch(takeMe(item.id));

    shouldShowWorkdayReminder(item.workDays);

    if (shouldShowWorkdayReminder(item.workDays)) {
      setIsWorkDayReminderVisible(true);
    }
  };

  const handlersOnPress: Partial<Record<KeysButtons, () => void>> = {
    takeToWork: handleTakeInWork,
    delegate: handleDelegate,
    close: () => setIsClose(true),
    takeBack: handleTakeBack,
    didYesterday: dealWith,
  };

  const delegatedButton = useMemo(() => {
    const key = item.delegatedToUser ? "takeBack" : "delegate";

    if (permissions.CAN_DELEGATE && item.type === "TASK" && item.subType === TaskSubtypes.Business) {
      return { [key]: { onClick: handlersOnPress[key] } };
    }
    return {};
  }, [item.delegatedToUser, permissions.CAN_DELEGATE]);

  const config = useMemo(
    (): Partial<Record<KeysButtons, IButton>> => ({
      didYesterday: { onClick: handlersOnPress.didYesterday },
      takeToWork: { onClick: handlersOnPress.takeToWork },
      ...delegatedButton,
      close: { onClick: handlersOnPress.close },
    }),
    [delegatedButton],
  );

  return (
    <div>
      <RenderWithCondition condition={isVisible}>
        <TaskActions pos={pos} configuration={config} />
      </RenderWithCondition>
      <ModalRenderWithCondition condition={isDelegate}>
        <ModalDelegate close={() => setIsDelegate(false)} delegatedToUser={item.delegatedToUser} task={item} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isClose}>
        <ModalClose isVisible={isClose} setIsVisible={setIsClose} onSave={handleClose} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isAddDay}>
        <PlanningWork
          isVisible={isAddDay}
          setIsVisible={setIsAddDay}
          isTime={false}
          isMultiple={false}
          startDate={toFormatDate(dateTrue)}
          startDays={item.workDays ?? []}
          tempObject={tempWorkDay}
          onSave={handleSaveTakeInWork}
          keyTitle="workDay"
          closeAfterPress={false}
          deadline={item.plannedEndDate}
        />
      </ModalRenderWithCondition>
      {WorkDaysModals}
    </div>
  );
};
