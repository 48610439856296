import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";

import { TextFont } from "../TextFont";

import { NotificationButton } from "./NotificationButton";
import { NotificationUI } from "./NotificationUI";

interface IProps {
  phraseId: string;
  phraseOkId?: string;
  onOk: (() => void) | undefined;
}

export const NotificationClear: FC<IProps> = ({ onOk, phraseId, phraseOkId = "continue" }) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <NotificationUI isVisible={isVisible} onClose={onOk} style={styles.modalWrapper}>
      <section style={styles.modal}>
        <TextFont style={styles.text}>
          <FormattedMessage id={phraseId} />
        </TextFont>
        <NotificationButton onHandle={onOk} setIsVisible={setIsVisible}>
          <TextFont style={{ ...styles.buttonText, fontWeight: "700" }}>
            <FormattedMessage id={phraseOkId} />
          </TextFont>
        </NotificationButton>
      </section>
    </NotificationUI>
  );
};

const styles = {
  modalWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "rgba(242, 242, 242, 0.8)",
    borderRadius: "14px",
  },
  text: {
    "text-align": "center",
    fontSize: "18px",
    color: "#000000",
    padding: "16px",
    fontWeight: "400",
  },
  buttonText: {
    width: "100%",
    "text-align": "center",
    textAlignVertical: "center",
    color: "#297952",
    fontSize: "18px",
  },
};
