import React, { FC, useState, useCallback, CSSProperties } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { RootState } from "@store/store";
import { ArrowSvg, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IMonthsOfYear {
  onMonthPress: (...args: any) => void;
  startDate?: string;
  containerStyles?: object; //CSSProperties;
  selectedMonths?: string[];
  isActiveDisabled?: boolean;
}

export const MonthsOfYear: FC<IMonthsOfYear> = ({ onMonthPress, startDate, isActiveDisabled = true, selectedMonths }: IMonthsOfYear) => {
  const { date } = useSelector((state: RootState) => state.date);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  const isDisabled = useCallback(
    (index: number) => {
      const startDayjs = dayjs(startDate);
      const currentDayjs = dayjs(`${currentYear}-${index + 1}`);

      return currentDayjs.isBefore(startDayjs, "month") || selectedMonths?.includes(currentDayjs.format("YYYY-MM"));
    },
    [startDate, currentYear, selectedMonths],
  );

  const isActive = useCallback((index: number) => index === date.month() && currentYear === date.year(), [currentYear, date]);

  const handleMonthPress = useCallback(
    (index: number) => {
      const active = isActiveDisabled ? isActive(index) : false;
      onMonthPress(dayjs(`${currentYear}-${index + 1}`), isDisabled(index) || active);
    },
    [onMonthPress, currentYear, isDisabled, isActiveDisabled, isActive],
  );

  return (
    <section style={styles.main}>
      <div style={styles.year}>
        <button onClick={() => setCurrentYear(currentYear - 1)}>
          <ArrowSvg type="left" fill="#5F6E67" width={26} height={26} />
        </button>
        <TextFont type="bold" style={styles.mainTitle}>
          {`${currentYear} год`}
        </TextFont>
        <button onClick={() => setCurrentYear(currentYear + 1)}>
          <ArrowSvg type="right" fill="#5F6E67" width={26} height={26} />
        </button>
      </div>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div style={styles.mainMonths}>
          {dayjs.months().map((month, index) => (
            <button
              key={month}
              onClick={() => handleMonthPress(index)}
              style={{
                ...styles.mainMonthsBlock,
                backgroundColor: isDisabled(index) ? "#ECF0EF" : globalState.colorSchema.lighGrey,
                border: isActive(index) ? `1px solid ${globalState.colorSchema.text.accent}` : "none",
              }}
            >
              <TextFont
                style={{
                  ...styles.mainMonthsText,
                  color: isDisabled(index) ? globalState.colorSchema.grey : globalState.colorSchema.text.main,
                  textTransform: "capitalize",
                }}
              >
                {month}
              </TextFont>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

const styles = {
  main: {
    width: "100%",
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: "700",
    color: globalState.colorSchema.text.main,
    marginBottom: 12,
  },
  mainMonths: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "flex-wrap": "wrap",
  },
  mainMonthsBlock: {
    "min-width": "30.5%",
    "justify-content": "center",
    "align-items": "center",
    "background-color": "#E3E9E7",
    "border-radius": 5,
    "margin-bottom": 10,
    "margin-right": 10,
    padding: "11px 0",
  },
  mainMonthsBlockActive: {
    "border-color": globalState.colorSchema.text.accent,
    "border-width": 1,
  },
  mainMonthsBlockDisabled: {
    backgroundColor: "#ECF0EF",
  },
  mainMonthsText: {
    fontSize: 14,
    fontWeight: "400",
    color: globalState.colorSchema.text.main,
    "text-align": "center",
  },
  mainMonthsTextDisabled: {
    color: globalState.colorSchema.grey,
  },
  back: {
    flex: 1.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backTitle: {
    fontSize: 16,
    color: "#297952",
    marginLeft: 5,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 0.5,
    borderBottomColor: "rgba(0, 0, 0, 0.3)",
    padding: 9,
    paddingBottom: 12,
  },
  headerTitle: {
    flex: 2,
    fontWeight: "700",
    fontSize: 16,
    color: globalState.colorSchema.text.main,
  },
  year: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "space-between",
    "padding-right": 15,
    "margin-bottom": 10,
  },
};
