import React, { FC, Dispatch, SetStateAction, useMemo, useState, useEffect } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { IExternalUser, TCreateExternalUser } from "@services/meet.service";
import { useEditExternalParticipantMutation } from "@services/userApi";
import { createExternalUser, selectExternalUsers } from "@store/externalUsers";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";

interface IProps {
  user?: IExternalUser;
  isVisible: boolean;
  participantEmail: string;
  isMultipleEmails?: boolean;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  closeParticipant: () => void;
  setIsBlockSearchCall?: React.Dispatch<React.SetStateAction<boolean>>;
  injectCreatedExternalParticipant?: (user: IExternalUser) => void;
  updateExternalParticipant?: (user: IExternalUser) => void;
  setSkipSearchByEmail?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsManualSearchByEmailsLock?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InviteExternalParticipantBottomSheet: FC<IProps> = ({
  user,
  isVisible,
  participantEmail,
  isMultipleEmails = false,
  setSearch,
  setIsVisible,
  setIsBlockSearchCall,
  injectCreatedExternalParticipant,
  updateExternalParticipant,
  closeParticipant,
  setSkipSearchByEmail,
  setIsManualSearchByEmailsLock,
}) => {
  const [editExternalParticipant] = useEditExternalParticipantMutation();

  const { directorId } = userSelector();
  const { isLoading } = useSelector(selectExternalUsers);

  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.lastName ?? "");

  const dispatch = useDispatch();

  const isEdit = useMemo(() => firstName.length && lastName.length, [firstName, lastName]);

  const placeholderMeetName = useIntl().formatMessage({ id: "firstName" });
  const placeholderMeetDescription = useIntl().formatMessage({ id: "lastName" });

  const handleCloseModal = () => {
    setIsVisible(false);
    closeParticipant?.();
  };

  useEffect(() => {
    if (user) {
      setIsManualSearchByEmailsLock?.(true);
      setSkipSearchByEmail?.(false);
    }
  }, [user]);

  const handlePressSave = async () => {
    if (!(participantEmail || user) || !firstName || !lastName) return;

    const body: TCreateExternalUser = {
      email: user?.email ?? participantEmail,
      firstName,
      lastName,
      deleted: false,
    };

    if (user) {
      setIsManualSearchByEmailsLock?.(false);
      updateExternalParticipant?.({ ...user, firstName, lastName });

      await editExternalParticipant({
        externalUserId: user.id,
        data: { firstName, lastName },
        ...(directorId && { userId: directorId }),
      });
    } else {
      const response = await dispatch(createExternalUser(body));

      handleCloseModal();
      setIsBlockSearchCall?.(true);

      if (response && response?.meta?.requestStatus === "fulfilled" && response?.payload) {
        injectCreatedExternalParticipant?.(response.payload);
      }
    }

    if (!isMultipleEmails) {
      setSearch?.("");
    }

    setIsBlockSearchCall?.(true);
    handleCloseModal();
  };

  return (
    <ModalUI isVisible={isVisible} onClose={handleCloseModal}>
      <HeaderModal title=" " leftSide={{ onPressClose: handleCloseModal, isHideCancel: true }} />

      <ContentWrap>
        <Content>
          <InputName
            marginTop={20}
            placeholder={placeholderMeetName}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoFocus={true}
            maxLength={100}
          />
          <InputName
            marginTop={16}
            placeholder={placeholderMeetDescription}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={100}
          />

          <ButtonSave disabled={!isEdit} isEdit={!!isEdit} onClick={handlePressSave}>
            <LoaderButtonRenderWithCondition condition={isLoading}>
              <TextButton size={18} color={Colors.LIGHT.white} weight="700">
                <FormattedMessage id={user ? "save" : "add"} />
              </TextButton>
            </LoaderButtonRenderWithCondition>
          </ButtonSave>
        </Content>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  padding: 0 17px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputName = styled.input<{ marginTop: number }>`
  border-radius: 9px;
  padding: 16px;
  border: 1px solid ${Colors.LIGHT.grey};
  font-size: 18px;
  margin-top: ${(props) => props.marginTop}px;
`;
const ButtonSave = styled.button<{ isEdit: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  margin-top: 15px;
  background-color: ${(props) => (props.isEdit ? Colors.LIGHT.background.green : Colors.LIGHT.lighGrey)};
`;
const TextButton = styled(TextFont)`
  margin: 12px;
  color: ${Colors.LIGHT.red};
`;
